import React, { useState, useEffect } from 'react';

import { Box } from '@material-ui/core';
import { get, find, findIndex } from 'lodash';
import PropTypes from 'prop-types';

import { PageHeader, TabPanelItem, TabsComponent } from 'components';
import { useAuthState } from 'contexts/AuthContext';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { planogramsModel } from 'models';
import { ROUTES } from 'routes/constants';
import { getTWithPathKey } from 'utils';
import { withSuccessActionsDialog, withSuccessActionsDialogProps } from 'utils/enhancers';

import { PLANOGRAM_VERSION_STATUS } from '../constants';

import { CustomOverlaySettings } from './CustomOverlaySettings/CustomOverlaySettings';
import { Ecommerce } from './ecommerce/Ecommerce';
import { FaviconSettings } from './FaviconSettings/FaviconSettings';
import { GalleryOverlaySettings } from './GalleryOverlaySettings/GalleryOverlaySettings';
import { InfoOverlaysComponent } from './InfoOverlaysComponent/InfoOverlaysComponent';
import { LogoSettings } from './LogoSettings/LogoSettings';
import { SharingButtonSettings } from './SharingButtonSettings/SharingButtonSettings';
import { AutoRefreshSettings } from './AutoRefreshSettings/AutoRefreshSettings';
import { AnimationSettings } from './AnimationSettings/AnimationSettings';
import { AudioSettings } from './AudioSettings/AudioSettings';
import { EntranceAnimationSettings} from './EntranceAnimationSettings/EntranceAnimationSettings';
import { SphereLoaderSettings } from './SphereLoaderSettings/SphereLoaderSettings';
import NavigationSettings from './NavigationSettings/NavigationSettings';
import NavigationArrows from './NavigationArrows/NavigationArrows';
import { SearchIconSettings } from './SearchSettings/SearchIconSettings/SearchIconSettings';

const TAB_INDEXES = {
  GENERAL: 0,
  ECOMMERCE: 1,
  OVERLAYS: 2,
  NAVIGATION: 3
};

const TABS = [
  { index: TAB_INDEXES.GENERAL, titleTransKey: 'tabsNames.general' },
  { index: TAB_INDEXES.ECOMMERCE, titleTransKey: 'tabsNames.ecommerce' },
  { index: TAB_INDEXES.OVERLAYS, titleTransKey: 'tabsNames.overlays' },
  { index: TAB_INDEXES.NAVIGATION, titleTransKey: 'tabsNames.navigation' }
];

function ManagePlanogramSettings({
  match: {
    params: { id: planogramId },
  },
  setSuccessActionMessages,
}) {
  const { handleServerError } = useServerErrorActions();
  const {
    currentUser: {
      attributes: { shoppingPlatformName },
    },
  } = useAuthState();
  const getT = getTWithPathKey('pages.planograms.ManagePlanogramSettings');

  const [planogram, setPlanogram] = useState({});
  const [planogramVersion, setPlanogramVersion] = useState({});
  const [planogramLanguage, setPlanogramLanguage] = useState('');
  const [activeTab, setActiveTab] = useState(TAB_INDEXES.GENERAL);
  const { withPageProgressHandler } = useBusyProgressActions();

  useEffect(() => {
    getPlanogram();
  }, [planogramId]);

  const tabs = TABS.filter(({ index }) => (index === TAB_INDEXES.ECOMMERCE ? shoppingPlatformName : true));

  const getPlanogram = withPageProgressHandler(() =>
    planogramsModel
      .getPlanogram(planogramId)
      .then(planogramData => {
        const version = find(get(planogramData, 'planogramVersions', []), ['status', PLANOGRAM_VERSION_STATUS.draft]);

        const planogramNameArray = planogramData.name.split('_');
        const languageCode = planogramNameArray[planogramNameArray.length - 1] || 'en';

        setPlanogram(planogramData);
        setPlanogramVersion({ ...version, audioSettings: {} });
        setPlanogramLanguage(languageCode);
      })
      .catch(handleServerError),
  );

  const getActualIndex = tabIndex => findIndex(tabs, item => item.index === tabIndex);

  return (
    <>
      <PageHeader
        title={getT('title')}
        backLink={ROUTES.showSphere.replace(':id', planogram.id)}
        backLinkText={planogram.name}
      />
      <Box mb={5}>
        <TabsComponent
          value={activeTab}
          setValue={setActiveTab}
          tabNames={tabs.map(({ titleTransKey }) => getT(titleTransKey))}
        />
      </Box>
      <TabPanelItem value={activeTab} index={getActualIndex(TAB_INDEXES.GENERAL)}>
        {planogramId && planogramVersion.id && (
          <>
            <AutoRefreshSettings
              planogramId={planogramId}
              planogramVersion={planogramVersion}
              handleServerError={handleServerError}
              setSuccessActionMessages={setSuccessActionMessages}
            />
            <AnimationSettings
              planogramId={planogramId}
              planogramVersion={planogramVersion}
              handleServerError={handleServerError}
              setSuccessActionMessages={setSuccessActionMessages}
            />
            <LogoSettings
              planogramId={planogramId}
              planogramVersion={planogramVersion}
              handleServerError={handleServerError}
              setSuccessActionMessages={setSuccessActionMessages}
            />
            <AudioSettings
              planogramId={planogramId}
              planogramVersion={planogramVersion}
              handleServerError={handleServerError}
              setSuccessActionMessages={setSuccessActionMessages}
            />
            <SharingButtonSettings
              planogramId={planogramId}
              planogramVersion={planogramVersion}
              handleServerError={handleServerError}
              setSuccessActionMessages={setSuccessActionMessages}
            />
            {!shoppingPlatformName && (
              <GalleryOverlaySettings
                planogramId={planogramId}
                planogramVersion={planogramVersion}
                handleServerError={handleServerError}
                setSuccessActionMessages={setSuccessActionMessages}
              />
            )}
            <FaviconSettings
              planogramId={planogramId}
              planogramVersion={planogramVersion}
              handleServerError={handleServerError}
              setSuccessActionMessages={setSuccessActionMessages}
            />
            <SearchIconSettings
              planogramId={planogramId}
              planogramVersion={planogramVersion}
              handleServerError={handleServerError}
              setSuccessActionMessages={setSuccessActionMessages}
            />
            <SphereLoaderSettings
              planogramVersion={planogramVersion}
              planogramId={planogramId}
              handleServerError={handleServerError}
              setSuccessActionMessages={setSuccessActionMessages}
            />
            <EntranceAnimationSettings
              planogramId={planogramId}
              planogramVersion={planogramVersion}
              handleServerError={handleServerError}
              setSuccessActionMessages={setSuccessActionMessages}
            />
          </>
        )}
      </TabPanelItem>
      <TabPanelItem value={activeTab} index={getActualIndex(TAB_INDEXES.ECOMMERCE)}>
        {planogramId && planogramVersion.id && (
          <Ecommerce
            planogramId={planogramId}
            planogramVersionId={planogramVersion.id}
            planogramLanguage={planogramLanguage}
          />
        )}
      </TabPanelItem>
      <TabPanelItem value={activeTab} index={getActualIndex(TAB_INDEXES.OVERLAYS)}>
        {planogramId && planogramVersion.id && (
          <>
            <CustomOverlaySettings
              planogramId={planogramId}
              planogramVersion={planogramVersion}
              handleServerError={handleServerError}
              setSuccessActionMessages={setSuccessActionMessages}
            />
            <InfoOverlaysComponent
              planogramId={planogramId}
              planogramVersion={planogramVersion}
              handleServerError={handleServerError}
              setSuccessActionMessages={setSuccessActionMessages}
            />
          </>
        )}
      </TabPanelItem>
      <TabPanelItem value={activeTab} index={getActualIndex(TAB_INDEXES.NAVIGATION)}>
        {planogramId && planogramVersion.id && (
          <>
            <NavigationSettings
              planogramId={planogramId}
              planogramVersion={planogramVersion}
              handleServerError={handleServerError}
              setSuccessActionMessages={setSuccessActionMessages}
            />
            <NavigationArrows
              planogramId={planogramId}
              planogramName={planogram.name}
              planogramVersion={planogramVersion}
              handleServerError={handleServerError}
              setSuccessActionMessages={setSuccessActionMessages}
            />
          </>
        )}
      </TabPanelItem>
    </>
  );
}

ManagePlanogramSettings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
  ...withSuccessActionsDialogProps,
};

export default withSuccessActionsDialog(ManagePlanogramSettings);
