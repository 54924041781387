import React, { useEffect, useState, useMemo } from 'react';

import { Trans } from 'react-i18next';
import { Box, Grid, Button } from '@material-ui/core';

import { iframeContentsModel } from 'models/IframeContentsModel';
import { CardComponent, ImageUploader, PageHeader } from 'components';
import { FileWithSignedId, Image, PaginationProps } from 'types';
import { IframeContent } from 'types/other';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { getTWithPathKey } from 'utils';
import { ROUTES } from 'routes/constants';
import { DOWNLOAD_SECTIONS } from 'contexts/UploadStatusContext/constants';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { ACCEPT_EXTENSIONS } from 'components/ImageUploader/constants';
import { LINKS } from 'components/constants';
import CssIcon from 'assets/icons/assets/css-icon.svg';
import HtmlIcon from 'assets/icons/assets/html-icon.svg';
import JsIcon from 'assets/icons/assets/js-icon.svg';
import PdfIcon from 'assets/icons/assets/pdf-icon.svg';
import { withPagination } from 'utils/enhancers';
import { useDeleteSelectedAssets } from 'hooks';

function TransLink({ to, children }: { to: string; children?: React.ReactNode }) {
  return (
    <a href={to} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
}

const filters: Array<{ query: string; label: string }> = [
  { query: '', label: 'All files' },
  { query: 'image/svg+xml', label: 'SVG' },
  { query: 'application/pdf', label: 'PDF' },
  { query: 'image/png', label: 'PNG' },
  { query: 'image/jpeg', label: 'JPG' },
  { query: 'image/gif', label: 'GIF' },
  { query: 'text/html', label: 'HTML' },
  { query: 'text/javascript', label: 'JS' },
];

const fallbacks: Record<string, string> = {
  'text/css': CssIcon,
  'text/html': HtmlIcon,
  'text/javascript': JsIcon,
  'application/pdf': PdfIcon,
};

export default withPagination(
  ({ paginationItemsCount, paginationParams, setPaginationItemsCount, resetPaginationPage }: PaginationProps) => {
    const { handleServerError } = useServerErrorActions();
    const { withPageProgressHandler } = useBusyProgressActions();
    const { handleDeleteAllRequest } = useDeleteSelectedAssets();

    const getT = getTWithPathKey('pages.assets.OverlayContent');

    const [typeFilter, setTypeFilter] = useState<string>('');
    const [contents, setContents] = useState<IframeContent[]>([]);

    const updateContents = () =>
      iframeContentsModel.getIframeContents({ content_type: typeFilter, ...paginationParams }).then(data => {
        setContents(data.iframeContents);
        setPaginationItemsCount(data.count);
      });

    useEffect(() => {
      updateContents();
    }, [typeFilter, paginationParams]);

    const loadImage = withPageProgressHandler((file: FileWithSignedId) =>
      iframeContentsModel
        .createIframeContent({
          seoDesc: '',
          seoTitle: file.name,
          h1Tag: '',
          signedId: file.signed_id,
        })
        .then(resetPaginationPage)
        .catch(handleServerError),
    );

    const unloadImage = withPageProgressHandler((imageId: number) =>
      iframeContentsModel.deleteIframeContent(imageId).then(updateContents).catch(handleServerError),
    );

    const handleEdit = withPageProgressHandler((imageId: number, image: Image) =>
      iframeContentsModel
        .updateIframeContent(imageId, {
          seoDesc: image.accessibilityDescription ?? '',
          seoTitle: image.seoTitle ?? '',
        })
        .then(updateContents)
        .catch(handleServerError),
    );

    const images = useMemo<Image[]>(
      () =>
        contents.map<Image>(c => ({
          ...c,
          accessibilityDescription: c.seoDesc,
        })),
      [contents],
    );

    return (
      <>
        <PageHeader title={`${getT('title')}: ${paginationItemsCount}`} backLink={ROUTES.assets} />
        <Grid container spacing={6}>
          <CardComponent sm={12} title={getT('cardTitle')}>
            <Box>
              <Trans
                i18nKey={getT('description')}
                components={{ a: <TransLink to={LINKS.overlayGuide} />, br: <br /> }}
              />
            </Box>
            <Box mt={5}>
              {filters.map(it => (
                <Button
                  key={it.query}
                  onClick={() => setTypeFilter(it.query)}
                  color={it.query === typeFilter ? 'primary' : 'inherit'}
                  variant="contained"
                  size="small"
                  style={{ marginRight: 5 }}
                >
                  {it.label}
                </Button>
              ))}
            </Box>
            <ImageUploader
              images={images}
              loadImage={loadImage}
              section={DOWNLOAD_SECTIONS.overlayContent}
              unloadImage={unloadImage}
              handleDeleteAll={assets =>
                handleDeleteAllRequest(assets, iframeContentsModel.deleteIframeContent, updateContents)
              }
              handleServerError={handleServerError}
              withEdit
              handleEdit={handleEdit}
              withUploadButton
              uploadButtonText={getT('uploadButton')}
              text=""
              accept={ACCEPT_EXTENSIONS.contentOverlay}
              fallbacks={fallbacks}
              withLink
            />
          </CardComponent>
        </Grid>
      </>
    );
  },
);
