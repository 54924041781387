import React, { useState, useEffect } from 'react';

import { Grid, IconButton } from '@material-ui/core';
import { get, isEmpty, parseInt } from 'lodash';
import PropTypes from 'prop-types';

import { PageHeader, LinkComponent, CardComponent, ItemWithData } from 'components';
import { PlanogramEditDialog, ClonePlanogramDialog } from 'components/Dialogs';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { useTimeoutUpdate, usePlanogramClone } from 'hooks';
import { planogramGroupsModel, planogramsModel } from 'models';
import { ROUTES } from 'routes/constants';
import { getTWithPathKey } from 'utils';

import { PLANOGRAM_VERSION_STATUS } from '../constants';

import { PlanogramVersionsList } from './PlanogramVersionsList/PlanogramVersionsList';

import { EditIcon, GreenTypography, Typography } from './style';
import { CalendarTodayIcon } from 'components/DateComponent/style';

export default function ShowPlanogram({
  match: {
    params: { id },
  },
}) {
  const [planogram, setPlanogram] = useState({});
  const [planogramVersions, setPlanogramVersions] = useState([]);
  const [planogramGroup, setPlanogramGroup] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [toggleStatusDialog, setToggleStatusDialog] = useState(false);
  const { handleServerError } = useServerErrorActions();
  const { withPageProgressHandler } = useBusyProgressActions();
  const getT = key => getTWithPathKey('pages.planograms.showPlanogram')(key);

  const {
    openDialog: openCloneDialog,
    clonePlanogram,
    isCloneLoading,
    handleCloneSubmit,
    handleCloneDialogClose,
    handleCloneAction,
  } = usePlanogramClone();

  useEffect(() => {
    getPlanogram();
    getPlanogramGroup();
  }, [id]);

  const getPlanogram = withPageProgressHandler(() =>
    planogramsModel
      .getPlanogram(id)
      .then(data => {
        setPlanogram(data);
        setPlanogramVersions(get(data, 'planogramVersions', []));
      })
      .catch(error => handleServerError(error)),
  );

  const getPlanogramGroup = withPageProgressHandler(() =>
    planogramGroupsModel
      .getPlanogramGroupsList()
      .then(planogramGroups => setPlanogramGroup(planogramGroups))
      .catch(error => handleServerError(error)),
  );

  const getVersion = versionRule => planogramVersions.find(version => version.status === versionRule) || {};

  const shouldUpdate = () =>
    Boolean(planogram.editable) &&
    (!isEmpty(getVersion(PLANOGRAM_VERSION_STATUS.publishing)) ||
      !isEmpty(getVersion(PLANOGRAM_VERSION_STATUS.generating)) ||
      !isEmpty(getVersion(PLANOGRAM_VERSION_STATUS.drafting)));

  const handleEdit = ({ id, ...data }) =>
    planogramsModel
      .editPlanogram(id, data)
      .then(data => {
        setPlanogram(data.planogram);
        setPlanogramVersions(get(data, 'planogram.planogramVersions', []));
      })
      .catch(handleServerError);

  const getPublishedVersionLink = () => {
    const publishedVersion = planogramVersions.find(version => version.status === PLANOGRAM_VERSION_STATUS.published);

    return get(publishedVersion, 'url', '');
  };

  const editPlanogramVersion = ({ id, versionId, data }) =>
    planogramsModel.editVersion(id, versionId, data).then(() => getPlanogram());

  const regenerateCredentials = ({ id, versionId }) =>
    planogramsModel.regeneratePublishedCredentials(id, versionId).then(() => getPlanogram());

  const handleClose = () => setOpenDialog(false);

  const handleOpenEdit = () => setOpenDialog(true);

  const handleToggleStatusDialog = () => setToggleStatusDialog(prevState => !prevState);

  useTimeoutUpdate(shouldUpdate(), getPlanogram, planogramVersions);

  const renderInfoNav = () => (
    <IconButton onClick={handleOpenEdit} disabled={!planogram.editable}>
      <EditIcon />
    </IconButton>
  );

  return (
    <>
      <PageHeader title={planogram.name} backLink={ROUTES.manageSpheres} />
      <Grid container spacing={6}>
        <Grid item xs={12} sm={8}>
          {planogram.editable && (
            <PlanogramVersionsList
              planogramVersions={planogramVersions}
              updatePlanogram={getPlanogram}
              handleServerError={handleServerError}
              planogramId={parseInt(id)}
              planogramName={planogram.name}
              isLegacy={planogram.legacy}
              editPlanogramVersion={editPlanogramVersion}
              regenerateCredentials={regenerateCredentials}
              handleCloneAction={handleCloneAction}
            />
          )}
          {planogram.editable === false && (
            <Typography>
              {getT('curatorFeatureHint')}
              <LinkComponent href={getPublishedVersionLink()} className="blue" target="_blank" />
            </Typography>
          )}
        </Grid>
        <Grid item sm={4}>
          <CardComponent key="updatesBlock" title={getT('updatesBlock')} sm={12}>
            <ItemWithData
              key="created"
              title={getT('created')}
              data={planogram.createdAt}
              TitleIcon={CalendarTodayIcon}
            />
            <ItemWithData
              key="updated"
              title={getT('updated')}
              data={planogram.updatedAt}
              TitleIcon={CalendarTodayIcon}
            />
          </CardComponent>
          <CardComponent key="infoBlock" title={getT('infoBlock')} sm={12}>
            {planogram.legacy && (
              <ItemWithData key="legacy" title={getT('legacy')}>
                <GreenTypography variant="h5">{getT('legacyData')}</GreenTypography>
              </ItemWithData>
            )}
            {planogram.hosts &&
              planogram.hosts.map((host, index) => (
                <ItemWithData
                  key="Domain"
                  title={index === 0 ? getT('domain') : ''}
                  data={host}
                  hideColon={index !== 0}
                />
              ))}
            {planogram.isLanding && (
              <ItemWithData key="isLanding" title={getT('isLanding')} data={getT('landingSphereText')} />
            )}
          </CardComponent>
          <CardComponent
            key="seoAndAccessibilityBlock"
            title={getT('seoAndAccessibilityBlock')}
            sm={12}
            CardHeaderNav={renderInfoNav}
          >
            <ItemWithData key="seoTitle" title={getT('seoTitle')} data={planogram.seoTitle || ''} />
            <ItemWithData key="seoDesc" title={getT('seoDesc')} data={planogram.seoDesc || ''} />
            {planogram.accessibilityDescription && (
              <ItemWithData
                key="accessibilityDescription"
                title={getT('accessibilityDescription')}
                data={planogram.accessibilityDescription}
              />
            )}
          </CardComponent>
        </Grid>
      </Grid>
      {openCloneDialog && (
        <ClonePlanogramDialog
          open={openCloneDialog}
          handleServerError={handleServerError}
          planogram={clonePlanogram}
          planogramGroup={planogramGroup}
          isLoading={isCloneLoading}
          handleSubmit={handleCloneSubmit}
          handleClose={handleCloneDialogClose}
          handleToggleStatusDialog={handleToggleStatusDialog}
        />
      )}
      <PlanogramEditDialog open={openDialog} planogram={planogram} handleEdit={handleEdit} handleClose={handleClose} />
    </>
  );
}

ShowPlanogram.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
};
