import React from 'react';

import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';

export function VideoPlayIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.79 4.3a2.429 2.429 0 0 0-3.6 2.131v19.137a2.432 2.432 0 0 0 3.6 2.132l17.425-9.569a2.43 2.43 0 0 0 0-4.263L9.789 4.3zM8.62 6.43 26.045 16 8.62 25.568V6.431z" fill="#fff"/>
    </SvgIcon>
  );
}
