const generateRandomValue = () => {
  // golden ratio value for reference visit this article
  // https://martin.ankerl.com/2009/12/09/how-to-create-random-colors-programmatically/
  const goldenRatioConjugate = 0.618033988749895;
  
  return (Math.random() + goldenRatioConjugate) % 1;
}

const rgbToHex = (r: number, g: number, b: number) =>
  `#${  [r, g, b].map(colorChannel => {
    const hex = colorChannel.toString(16);

    return hex.length === 1 ? `0${  hex}` : hex
  }).join('')}`;

export const getRandomColor = (
  hue = generateRandomValue(),
  saturation = 0.5,
  value = 0.95
): string => {
  let r = 1;
  let g = 1;
  let b = 1;
  const hueRange = Math.floor(hue * 6);
  const f = hue * 6 - hueRange;
  const p = value * (1 - saturation);
  const q = value * (1 - f * saturation);
  const t = value * (1 - (1 - f) * saturation);
  
  if (hueRange === 0) {
    r = value;
    g = t;
    b = p;
  }
  
  if (hueRange === 1) {
    r = q;
    g = value;
    b = p;
  }
  
  if (hueRange === 2) {
    r = p;
    g = value;
    b = t;
  }
  
  if (hueRange === 3) {
    r = p;
    g = q;
    b = value;
  }

  if (hueRange === 4) {
    r = t;
    g = p;
    b = value;
  }

  if (hueRange === 5) {
    r = value;
    g = p;
    b = q;
  }

  return rgbToHex(Math.floor(r * 256), Math.floor(g * 256), Math.floor(b * 256));
}
