import { styled, Typography } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const Subtitle = styled(Typography)({
  fontSize: '14px',
  lineHeight: '1.43',
  color: COLORS.cozmosPurple2,
});

export const Caption = styled(Typography)({
  fontSize: '12px',
  lineHeight: '1.5',
  color: COLORS.cozmosPurple2,
});
