import { DOWNLOAD_SECTIONS } from 'contexts/UploadStatusContext/constants';

export const EXTENSIONS_NAMES = {
  png: 'png',
  jpg: 'jpg',
  jpeg: 'jpeg',
  svg: 'svg',
  pdf: 'pdf',
  mp4: 'mp4',
};

/* eslint-disable */
export const ACCEPT_EXTENSIONS = {
  imgIcon: `.${EXTENSIONS_NAMES.jpeg}, .${EXTENSIONS_NAMES.jpg}, .${EXTENSIONS_NAMES.png}, .${EXTENSIONS_NAMES.svg}`,
  img: `.${EXTENSIONS_NAMES.jpeg}, .${EXTENSIONS_NAMES.jpg}, .${EXTENSIONS_NAMES.png}`,
  imgAndVideo: `.${EXTENSIONS_NAMES.jpeg}, .${EXTENSIONS_NAMES.jpg}, .${EXTENSIONS_NAMES.png}, .${EXTENSIONS_NAMES.mp4}`,
  web: `.html, .css, .js`,
  video: '.mp4, .webm',
  contentOverlay: ``,
  audio: '.mp3, .wav, .aac',
};
ACCEPT_EXTENSIONS.contentOverlay = `${ACCEPT_EXTENSIONS.imgIcon}, ${ACCEPT_EXTENSIONS.web}, .gif, .pdf`;
/* eslint-enable */

// export const bgImageResolutionsString = '130048x32768, 65024x16384, 32512x8192, 16256x4096, 130304x32768, 65152x16384, 32576x8192, 16288x4096';
//
// export const BG_IMAGE_RATIOS = bgImageResolutionsString.split(', ').map(ratio => {
//   const [width, height] = ratio.split('x');
//   return { width: Number(width), height: Number(height) };
// });

export const sectionsWithAccessibilityHintTextFullWidth = [DOWNLOAD_SECTIONS.images, DOWNLOAD_SECTIONS.productImages];
