export const ALLOWED_ORDER_BY = {
  name: 'name',
  picturesCount: 'pictures_count',
  updatedAt: 'updated_at',
  adminName: 'admin_name',
  identifier: 'identifier',
  categoryName: 'category_name',
};

export const ALLOWED_ORDER_DIR = {
  asc: 'asc',
  desc: 'desc',
};

export const IMAGE_UPLOAD_CAPTION = {
  logo: 'Choose a file from already uploaded logo assets. The logo size is shown in the preview and will be aligned to the top left corner of the Sphere when generated.',
  controlButton:
    'Choose a file from already uploaded control button assets. Set the default size for the button. Real size is presenting for Full HD.',
  favicon:
    'The default favicon is the Cozmos symbol. You can replace it by choosing a file from already uploaded favicon assets.',
  searchIcon: 'This icon will represent the current sphere in the search results.',
  entranceAnimationIcon: 'Default icon is set. You can replace it by choosing an uploaded file from Assets / UI Images'
};

export const LINKS = {
  sphereKnowledgeBase: 'https://learn.cozmos.com/',
  knowledgeBaseQuickStart: 'https://learn.cozmos.com/quick-start',
  overlayGuide: 'https://learn.cozmos.com/guides/overlays',
  productsKnowledgeBase: 'https://learn.cozmos.com/',
  categoriesKnowledgeBase: 'https://learn.cozmos.com/',
  userManagementKnowledgeBase: 'https://learn.cozmos.com/',
  backgroundsKnowledgeBase: 'https://learn.cozmos.com/',
  cozmosDiscordCommunity: 'https://discord.gg/mRm7qNQaMR',
  terms: 'https://learn.cozmos.com/terms-of-use',
  privacyPolicy: 'https://learn.cozmos.com/privacy-policy',
  contactEmail: 'support@cozmos.com',
  reservedClientNames:
    'https://github.com/Sphere-Research/admin-panel-api/blob/develop/app/services/reserved_names_helper.rb',
  instagramConnectionGuide: 'https://learn.cozmos.com/guides/social-media',
  toysphereAutoplay: 'https://toysphere.com/en/starwars/cempire_strikes_back?autoplay=true',
  animationKnowledgeBase: 'https://learn.cozmos.com/guides/animation',
};
