import React, { useEffect, useRef, useState } from 'react';

import { Grid, Box, Typography } from '@material-ui/core';
import PauseIcon from '@material-ui/icons/Pause';
import CloseIcon from '@material-ui/icons/Close';

import { VideoPlayIcon } from 'components/CustomIcons';
import { LINKS } from 'components/constants';
import { ButtonComponent } from 'components/ButtonComponent/ButtonComponent';
import { ENTRANCE_MODAL_STORAGE_KEY } from 'constants/technical';
import video from 'assets/videos/onboarding.mp4';

import { Dialog } from '../style';
import { VideoContainer, IconButton, VideoBackdrop, DialogContainer, IconWrapper } from './style';

export const EntranceDialog = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showModal, setShowModal] = useState(
    localStorage.getItem(ENTRANCE_MODAL_STORAGE_KEY) !== 'shown'
  );

  useEffect(() => {
    const videoElement = videoRef.current;
    const handleEndPlayback = () => setIsPlaying(false);

    if (videoElement) {
      videoElement.addEventListener('ended', handleEndPlayback);
    }
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('ended', handleEndPlayback);
      }
    };
  }, [isPlaying]);

  const handleClose = () => {
    setShowModal(false);
    localStorage.setItem(ENTRANCE_MODAL_STORAGE_KEY, 'shown');
  };

  const handleVideoClick = () => {
    if (!videoRef.current) {
      return;
    }

    const isPlaying = videoRef.current.paused;

    setIsPlaying(isPlaying);
    isPlaying ? videoRef.current.play() : videoRef.current.pause();
  }

  const handleButtonClick = () => window.open(LINKS.knowledgeBaseQuickStart);

  return (
    <Dialog
      className="entrance-dialog"
      open={showModal}
      onClose={handleClose}
      fullWidth
    >
      <DialogContainer>
        <VideoContainer display="flex" className={isPlaying ? 'playing' : ''} onClick={handleVideoClick}>
          <video
            style={{ objectFit: 'contain', width: '100%', height: '100%' }}
            controls={false}
            ref={videoRef}
            preload="metadata"
            src={video}
          />
          <VideoBackdrop />
          <IconWrapper>
            {isPlaying ?
              <PauseIcon /> :
              <VideoPlayIcon />
            }
          </IconWrapper>
        </VideoContainer>
        <Grid container alignItems="center" direction="column">
          <Grid item sm={6} lg={5} style={{ textAlign: 'center' }}>
            <Box py={12}>
              <Typography variant="h1">Welcome to Cozmos!</Typography>
              <Box pt={3} pb={6} style={{ margin: '0 auto', maxWidth: '83%' }}>
                <Typography>Watch these quick videos to learn more about what you can create in Cozmos.</Typography>
              </Box>
              <ButtonComponent
                onClick={handleButtonClick}
                text="Watch videos"
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContainer>
      <IconButton onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </Dialog>
  )
}
