import React, { useState, useEffect } from 'react';

import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { isEqual } from 'lodash';

import { CardComponent, ColorPicker, ImageChooseComponent, NavigationArrowsPreview, SettingsForm } from 'components';
import { AutocompleteValidatorComponent } from 'components/FormComponents';
import { VALIDATORS_MAP } from 'components/FormComponents/const';
import { PaginationParams, PlanogramVersion } from 'types';
import { useSubmittingState } from 'hooks';
import { otherAssetsModel, planogramsModel } from 'models';
import { NavigationArrowType } from 'types/enums';
import { AnimationPath, AnimationPathType, NavigationArrow, NavigationArrowAsset } from 'types/other';

import { Switch } from 'components/style';
import { Checkbox } from '../SharingButtonSettings/style';
import { Subtitle, Caption } from './style';

const FORM_ID = 'navigationArrows';

interface NavigationArrowsProps {
  planogramVersion: PlanogramVersion;
  planogramId: number;
  planogramName: string;
  handleServerError: (err: any) => void;
  setSuccessActionMessages: (messages: string[]) => void;
}

const defaultSettings: NavigationArrow = {
  id: 1,
  enabled: false,
  backgroundColor: '#dced75',
  disableGlowEffect: false,
  touchDeviceOnly: false,
  distributeArrows: false,
  animationPathId: '',
  navigationArrowOtherAssets: [],
};

const NavigationArrows = ({
  planogramId,
  planogramName,
  planogramVersion,
  handleServerError,
  setSuccessActionMessages,
}: NavigationArrowsProps) => {
  const [settings, setSettings] = useState<NavigationArrow>(planogramVersion.navigationArrow ?? defaultSettings);
  const [initialSettings, setInitialSettings] = useState<NavigationArrow>(
    planogramVersion.navigationArrow ?? defaultSettings,
  );
  const [animationPaths, setAnimationPaths] = useState<AnimationPath[]>([]);
  const leftArrowIndex = settings.navigationArrowOtherAssets.findIndex(
    image => image.direction === NavigationArrowType.LEFT,
  );
  const rightArrowIndex = settings.navigationArrowOtherAssets.findIndex(
    image => image.direction === NavigationArrowType.RIGHT,
  );
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();

  useEffect(() => {
    getAnimationPathData();
  }, []);

  const getAnimationPathData = () =>
    planogramsModel.getAnimationPath(planogramId).then(data => {
      const animationPaths = data.animationPaths.filter(
        path =>
          path.type === AnimationPathType.NAVIGATION_ARROWS &&
          path.items.every(item => `${item.planogramName}_${item.language}` === planogramName),
      );

      setAnimationPaths(animationPaths);
    });

  const getImages = (params: PaginationParams) => otherAssetsModel.getImages(params);

  const handleImageChange = (item: Record<string, any>, direction: NavigationArrowType) => {
    const imageIndex = direction === NavigationArrowType.LEFT ? leftArrowIndex : rightArrowIndex;

    setSettings(prevState => ({
      ...prevState,
      navigationArrowOtherAssets: settings.navigationArrowOtherAssets?.map(
        (image: NavigationArrowAsset, index: number) =>
          index === imageIndex ? { ...image, otherAsset: { ...image.otherAsset, ...item } } : { ...image },
      ),
    }));
  };

  const handleChange = (item: Record<string, any>) =>
    setSettings(prevState => ({
      ...prevState,
      ...item,
    }));

  const onSubmit = withHandlingSubmittingState(() => {
    const animationPath = animationPaths.find(path => path.id === settings.animationPathId);
    const data = {
      ...settings,
      animationPath,
      navigationArrowOtherAssets: settings.navigationArrowOtherAssets.map(image => ({
        ...image,
        otherAssetId: image.otherAsset.id ?? null,
      })),
    };

    return planogramsModel
      .editVersion(planogramId, planogramVersion.id, { navigationArrow: data })
      .then(data => {
        setSuccessActionMessages(data.success);
        setSettings(data.planogramVersion.navigationArrow);
        setInitialSettings(data.planogramVersion.navigationArrow);
      })
      .catch(handleServerError);
  });

  const renderNav = () => (
    <Switch checked={settings.enabled} onChange={e => handleChange({ enabled: e.target.checked })} />
  );

  const isSaveButtonVisible = !isSubmitting && !isEqual(settings, initialSettings);

  return (
    <SettingsForm isSaveButtonVisible={isSaveButtonVisible} handleSave={onSubmit} id={FORM_ID}>
      <CardComponent title="Navigation arrows" isLoading={isSubmitting} CardHeaderNav={renderNav}>
        {settings.enabled && (
          <Grid container alignItems="center">
            <Grid item sm={12}>
              <Box ml={-2.5} display="flex" alignItems="center">
                <Switch
                  checked={settings.touchDeviceOnly}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange({ touchDevicesOnly: event.target.checked })
                  }
                />
                <Typography variant="caption">Use on touch-devices only</Typography>
              </Box>
              <Box display="flex" my={2}>
                <Box ml={1}>
                  <Caption>
                    By default, we show arrows on all devices, set this to “Use on touch-devices only” to remove arrows
                    on desktop devices.
                  </Caption>
                </Box>
              </Box>
              <Box my={6}>
                <Divider />
              </Box>
              <Box ml={-2.5} display="flex" alignItems="center">
                <Switch
                  checked={settings.distributeArrows}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange({ distributeArrows: event.target.checked })
                  }
                />
                <Typography variant="caption">Distribute evenly on touch-devices</Typography>
              </Box>
              <Box display="flex" my={2}>
                <Box ml={1}>
                  <Caption>
                    By default, navigation arrows are placed in the middle of the screen, set this to “Distribute
                    evenly” for arrows to be placed by the screen sides for touch-devices.
                  </Caption>
                </Box>
              </Box>
              <Box my={6}>
                <Divider />
              </Box>
            </Grid>
            <Grid item sm={12}>
              <Box mb={3}>
                <Typography variant="h2" className="uppercase">
                  Set an animation path
                </Typography>
              </Box>
              <Box mb={4}>
                <Subtitle>Create an animation path for arrows to follow.</Subtitle>
              </Box>
              <Grid item sm={5}>
                <AutocompleteValidatorComponent
                  value={settings.animationPathId ?? ''}
                  fieldName="animationPathId"
                  label="Select an animation path"
                  handleChange={handleChange}
                  itemsNamePath="name"
                  disableMinHeight
                  disableClearable
                  validators={[VALIDATORS_MAP.required]}
                  selectList={animationPaths}
                />
              </Grid>
              <Box mt={1} display="flex">
                <Caption>
                  Please note that animation paths with auto-play or without loop options are not allowed. Arrows can
                  only use paths with steps all in 1 sphere for now. Animation paths that do not meet these criteria
                  will not be displayed in the dropdown
                </Caption>
              </Box>
              <Box my={6}>
                <Divider />
              </Box>
            </Grid>
            <Grid item sm={12}>
              <Box mb={3}>
                <Typography variant="h2" className="uppercase">
                  Arrows UI colour style
                </Typography>
              </Box>
              <Box mb={6}>
                <Subtitle>Use the options below to edit arrow styles</Subtitle>
              </Box>
              <Grid container spacing={10}>
                <Grid item xs={6} lg={3}>
                  <Box mb={2.5}>
                    <Typography variant="h4">Left arrow</Typography>
                  </Box>
                  <ImageChooseComponent
                    image={leftArrowIndex >= 0 && settings.navigationArrowOtherAssets?.[leftArrowIndex].otherAsset}
                    setImage={val => handleImageChange(val, NavigationArrowType.LEFT)}
                    handleServerError={handleServerError}
                    dialogTitle="Choose image"
                    getPictures={getImages}
                    className="min-height"
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Box mb={2.5}>
                    <Typography variant="h4">Right arrow</Typography>
                  </Box>
                  <ImageChooseComponent
                    image={rightArrowIndex >= 0 && settings.navigationArrowOtherAssets?.[rightArrowIndex].otherAsset}
                    setImage={val => handleImageChange(val, NavigationArrowType.RIGHT)}
                    handleServerError={handleServerError}
                    dialogTitle="Choose image"
                    getPictures={getImages}
                    className="min-height"
                  />
                </Grid>
              </Grid>
              <Grid item sm={12}>
                <Box my={10}>
                  <Typography variant="h4">
                    <span>Shadow / Glow effect</span>
                  </Typography>
                  <Box mt={3} display="flex">
                    <Checkbox
                      color="primary"
                      checked={!settings.disableGlowEffect}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange({ disableGlowEffect: !event.target.checked })
                      }
                    />
                    <ColorPicker
                      setColor={color => handleChange({ backgroundColor: color.hex })}
                      color={{ hex: settings.backgroundColor }}
                    />
                  </Box>
                </Box>
                <Box mt={6} mb={3}>
                  <Typography variant="h4">Preview</Typography>
                </Box>
                <NavigationArrowsPreview settings={settings} />
              </Grid>
            </Grid>
          </Grid>
        )}
      </CardComponent>
    </SettingsForm>
  );
};

export default NavigationArrows;
