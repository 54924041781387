import React, { useState, useEffect, useMemo } from 'react';

import { Trans } from 'react-i18next';
import { isArray } from 'lodash';
import { Box, Grid } from '@material-ui/core';

import { CardComponent, ImageUploader, PageHeader } from 'components';
import { getTWithPathKey } from 'utils';
import { ROUTES } from 'routes/constants';
import { DOWNLOAD_SECTIONS } from 'contexts/UploadStatusContext/constants';
import { ACCEPT_EXTENSIONS } from 'components/ImageUploader/constants';
import { videosModel } from 'models/VideosModel';
import { useDeleteSelectedAssets, useTimeoutUpdate } from 'hooks';
import { TagListTypes } from 'types/enums';
import { FileWithSignedId, Image, PaginationProps, Tag } from 'types';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { DropzoneVideo } from 'components/DropzoneVideo/DropzoneVideo';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { withPagination } from 'utils/enhancers';
import { tagsModel } from 'models';
import config from 'config/Config';

import { TagsList } from '../../images/TagsList/TagsList';

export default withPagination(
  ({ paginationItemsCount, paginationParams, setPaginationItemsCount, resetPaginationPage }: PaginationProps) => {
    const [tagId, setTagId] = useState<number | undefined>(undefined);
    const [shouldUpdateTags, setShouldUpdateTags] = useState(false);
    const [shouldUpdateVideos, setShouldUpdateVideos] = useState(false);
    const { handleServerError, resetServerErrors } = useServerErrorActions();
    const { withPageProgressHandler } = useBusyProgressActions();
    const { handleDeleteAllRequest } = useDeleteSelectedAssets();
    const getT = getTWithPathKey('pages.assets.Video');

    const [videos, setVideos] = useState<any[]>([]);

    const handleUpdateTags = () => setShouldUpdateTags(true);
    const handleUpdateVideos = () => setShouldUpdateVideos(true);

    const updateContents = withPageProgressHandler(() =>
      videosModel.getVideos({ ...paginationParams, instagram: false, tagId }).then(r => {
        setPaginationItemsCount(r.count);
        setVideos(r.videos);
      }));

    useEffect(() => {
      if (shouldUpdateVideos) {
        setShouldUpdateVideos(false);
      }
      updateContents();
    }, [tagId, paginationParams, shouldUpdateVideos]);

    const loadVideo = withPageProgressHandler((file: FileWithSignedId) =>
      videosModel
        .createVideo({
          seoDesc: '',
          seoTitle: file.name,
          h1Tag: '',
          signedId: file.signed_id,
        })
        .then(resetPaginationPage)
        .catch(handleServerError),
    );

    const images = useMemo<Image[]>(
      () =>
        videos.map<Image>(v => ({
          ...v,
          accessibilityDescription: v.seoDesc,
        })),
      [videos],
    );

    const unloadVideo = withPageProgressHandler((imageId: number) =>
      videosModel.deleteVideo(imageId).then(updateContents).catch(handleServerError),
    );

    const isVideoProcessing = useMemo(
      () => !images.every(video => video.curatorReady), [images]
    );

    const handleEdit = withPageProgressHandler((imageId: number, image: Image) =>
      videosModel
        .updateVideo(imageId, {
          seoDesc: image.accessibilityDescription ?? '',
          seoTitle: image.seoTitle ?? image.imageName,
        })
        .then(updateContents)
        .catch(handleServerError),
    );

    const onRemoveTagsFromVideo = (selectedImagesIds: number[]) =>
      tagsModel
        .deleteAsset({ id: tagId, tag: { pictureIds: [...selectedImagesIds] } })
        .then(() => {
          handleUpdateTags();
          updateContents();
        })
        .catch(handleServerError);

    const handleSelectTag = (tag: Tag) => () => {
      if (tag.id !== tagId) {
        setTagId(tag.id);
      }
    };

    const addVideoToTag = (tagId: number, pictureId: number[] | number) =>
      tagsModel
        .attachAssetsTag({
          tag: {
            videoIds: isArray(pictureId) ? [...pictureId] : [pictureId],
          },
          id: tagId,
        })
        .catch(error => handleServerError(error))
        .finally(() => {
          handleUpdateTags();
          handleUpdateVideos();
        });

    useTimeoutUpdate(isVideoProcessing, updateContents, images);

    return (
      <>
        <PageHeader title={`${getT('title')}: ${paginationItemsCount}`} backLink={ROUTES.assets} />
        <Grid container spacing={6} wrap="nowrap">
          <Grid item xs={12} sm={3} style={{ minWidth: '276px' }}>
            <TagsList
              activeTagId={tagId}
              handleSelect={handleSelectTag}
              shouldUpdateTags={shouldUpdateTags}
              setShouldUpdateTags={setShouldUpdateTags}
              setShouldUpdatePictures={setShouldUpdateVideos}
              handleServerError={handleServerError}
              resetServerErrors={resetServerErrors}
              actionsAfterAddingImage={handleUpdateVideos}
              addImageToTag={addVideoToTag}
              allAssetsText="All Videos"
              pageType={TagListTypes.VIDEOS}
            />
          </Grid>
          <CardComponent sm={12} title={getT('cardTitle')}>
            <Box>
              <Trans i18nKey={getT('description')} components={{ br: <br /> }} />
            </Box>
            <ImageUploader
              images={images}
              loadImage={loadVideo}
              section={DOWNLOAD_SECTIONS.video}
              unloadImage={unloadVideo}
              handleDeleteAll={assets => handleDeleteAllRequest(assets, videosModel.deleteVideo, updateContents)}
              handleServerError={handleServerError}
              shouldUpdatePictures={shouldUpdateVideos}
              selectButtonText="Detach from Tag"
              handleSelectComplete={onRemoveTagsFromVideo}
              withEdit
              withTags
              addAssetsToTag={addVideoToTag}
              handleEdit={handleEdit}
              withUploadButton
              uploadButtonText={getT('uploadButton')}
              text=""
              accept={config.app_env !== 'prod' ? ACCEPT_EXTENSIONS.video : '.mp4'}
              fallbacks={{ 'video/mp4': DropzoneVideo, 'video/webm': DropzoneVideo }}
            />
          </CardComponent>
        </Grid>
      </>
    );
  },
);
