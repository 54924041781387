import React, { useEffect, useState } from 'react';

import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import Helmet from 'react-helmet';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'routes/constants';
import { authModel } from 'models';
import { AUTH_HEADERS } from 'models/constants';
import { BodyS, HeadingL } from 'theme/globalStyles';

interface CloneRequestPageProps {
  isConfirmed: boolean;
}

const CloneRequestPage = ({ isConfirmed }: CloneRequestPageProps) => {
  const [pubtoolToken, setPubtoolToken] = useState<string>();
  const history = useHistory();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userAccessToken = urlParams.get('uat');

    if (!userAccessToken) {
      history.push(ROUTES.home);
      return;
    }

    const request = isConfirmed ? authModel.confirmCloning : authModel.declineCloning;

    request({ userAccessToken })
      .then(response => {
        setPubtoolToken(response.authToken.pubtoolToken);
      })
      .catch(() => {
        history.push(ROUTES.cloningExpired);
      });
  }, [isConfirmed, history]);

  const handleReturn = () => {
    if (pubtoolToken) {
      localStorage.setItem(AUTH_HEADERS.PUBTOOL_TOKEN, pubtoolToken);
    }
    history.push(ROUTES.home);
  };

  return pubtoolToken ? (
    <Box padding="100px 0">
      <Helmet title="Copy request" />
      <Grid container direction="column">
        <Grid item style={{ marginBottom: '12px' }}>
          <HeadingL align="center" style={{ fontWeight: 600 }}>
            {isConfirmed ? 'Copy request approved' : 'Copy request denied'}
          </HeadingL>
        </Grid>
        <Grid item style={{ marginBottom: '40px' }}>
          <BodyS align="center">You can now close this tab or return to the website.</BodyS>
        </Grid>
        <Grid item container alignItems="center" justify="center">
          <Button onClick={handleReturn} variant="contained" color="primary" size="small">
            Return to the website
          </Button>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <CircularProgress />
  );
};

export default CloneRequestPage;
