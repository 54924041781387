import { useState } from 'react';

import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { planogramsModel } from 'models';
import { PlanogramCloneParams, PlanogramCloneType } from 'types';
import { ServerErrorType } from 'contexts/ServerErrorContext/types';
import { usePlanogramCloningActions } from 'contexts/PlanogramCloningContext/PlanogramCloningContext';

const MINIMUM_DIALOG_DISPLAY_TIME = 5000;

interface UsePlanogramCloneType {
  openDialog: boolean;
  clonePlanogram: PlanogramCloneType | null;
  isCloneLoading: boolean;
  handleCloneSubmit: (data: PlanogramCloneParams) => Promise<void>;
  handleCloneDialogClose: (clear?: boolean) => void;
  handleCloneAction: (data: PlanogramCloneType) => void;
}

const usePlanogramClone = (onReload?: () => Promise<unknown>): UsePlanogramCloneType => {
  const [isCloneLoading, setCloneLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [clonePlanogram, setClonePlanogram] = useState<PlanogramCloneType | null>(null);
  const { storePlanogramId, clearState } = usePlanogramCloningActions();
  const { handleServerError, resetServerErrors } = useServerErrorActions();

  const handleCloneAction = (params: PlanogramCloneType) => {
    setClonePlanogram(params);
    setOpenDialog(true);
  };

  const handleCloneDialogClose = (clear: boolean = true) => {
    setOpenDialog(false);
    resetServerErrors();

    if (clear) {
      setClonePlanogram(null);
    }
  };

  const handleCloneSubmit = async (body: PlanogramCloneParams) => {
    const startTime = Date.now();

    try {
      const { planogramDestinationId, ...data } = body;

      setCloneLoading(true);
      storePlanogramId({
        id: body?.planogramId,
        sourceGroupId: body.sourceGroupId,
        planogramDestinationGroupId: body.planogramGroupId,
        planogramDestinationId: body.planogramDestinationId,
      });

      await planogramsModel.clonePlanogram(data);

      if (onReload) {
        await onReload();
      }
      const remainingTime = MINIMUM_DIALOG_DISPLAY_TIME - (Date.now() - startTime);

      setTimeout(
        () => {
          setCloneLoading(false);
          handleCloneDialogClose();
        },
        remainingTime > 0 ? remainingTime : 0,
      );
    } catch (e) {
      if (e) {
        handleServerError(e as ServerErrorType);

        setCloneLoading(false);
        clearState();
      }
    }
  };

  return {
    openDialog,
    clonePlanogram,
    isCloneLoading,
    handleCloneSubmit,
    handleCloneDialogClose,
    handleCloneAction,
  };
};

export default usePlanogramClone;
