import React from 'react';

import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';

export function TagEditIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.403 2.573a3.093 3.093 0 0 1 4.373 0l1.651 1.65a3.093 3.093 0 0 1 0 4.375l-1.65 1.65-.002.002-6.694 6.694a3.093 3.093 0 0 1-1.75.875l-3.467.495a1.925 1.925 0 0 1-2.178-2.178l.496-3.467a3.093 3.093 0 0 1 .874-1.75l6.695-6.695 1.652-1.651zM9.857 6.26l-5.73 5.73a1.577 1.577 0 0 0-.445.892l-.496 3.467a.41.41 0 0 0 .464.464l3.467-.495c.338-.049.651-.205.893-.447l5.73-5.729c-.681-.26-1.514-.723-2.337-1.545-.823-.823-1.287-1.656-1.546-2.337zm5.104 2.66a3.705 3.705 0 0 1-.513-.133c-.52-.174-1.241-.529-1.974-1.262-.733-.733-1.088-1.453-1.262-1.974a3.708 3.708 0 0 1-.133-.513l1.395-1.395a1.578 1.578 0 0 1 2.23 0l1.652 1.651a1.578 1.578 0 0 1 0 2.231l-1.395 1.395z" fill="currentColor"/>
    </SvgIcon>
  );
}
