import React from 'react';

import PropTypes from 'prop-types';

import { Image } from '../style';
import { FaviconBox } from './style';

export function FaviconPreview({ version, iconUrl }) {
  return (
    <FaviconBox className={version}>
      {iconUrl && <Image src={iconUrl} />}
      <span>Favicon preview</span>
    </FaviconBox>
  );
}

FaviconPreview.propTypes = {
  version: PropTypes.string,
  iconUrl: PropTypes.string,
};

FaviconPreview.defaultProps = { version: 'light', iconUrl: '' };
