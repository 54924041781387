import React from 'react';

import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';

export function ErrorIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 17 17">
      <path d="M9.166 11.833a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.742 3.503c.77-1.337 2.746-1.337 3.515 0l4.648 8.08c.764 1.329-.246 2.917-1.758 2.917H3.852c-1.512 0-2.522-1.588-1.757-2.918l4.647-8.08zm2.462.594c-.304-.53-1.105-.53-1.409 0l-4.648 8.08c-.278.483.07 1.123.705 1.123h9.295c.636 0 .983-.64.705-1.123l-4.648-8.08zM8.5 6.1c.334 0 .605.269.605.6v3c0 .331-.27.6-.605.6a.603.603 0 0 1-.606-.6v-3c0-.331.271-.6.606-.6z" fill="currentColor"/>
    </SvgIcon>
  );
}
