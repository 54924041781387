import { Box as MuiBox, styled } from '@material-ui/core';

import {COLORS} from 'theme/constants';

export const Box = styled(MuiBox)({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  zIndex: '11',
  top: '8px',
  right: '10px',
  flexWrap: 'wrap-reverse',
});
export const Dot = styled(MuiBox)({
  borderRadius: '50%',
  display: 'inline-block',
  margin: '1px',
  height: '12px',
  width: '12px',
  flex: 'none',
  border: `1px solid ${COLORS.white}`,
  '&.tag-label': {
    verticalAlign: 'middle',
    margin: '0 8px 0 -21px',
  },
});
