import { Checkbox as MuiCheckbox, Box as MuiBox, withStyles, StyleRulesCallback, Theme } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const size = { regular: '120px', small: '70px' };

const styles: StyleRulesCallback<Theme, {}, 'root'> = theme => ({
  root: {
    '&.preview-block': {
      position: 'relative',
      display: 'inline-block',
      verticalAlign: 'top',
      margin: '16px',
      minHeight: '100px',
      '&.selected .image-block': {
        border: `1px solid ${COLORS.cozmosBlue}`,
        boxShadow: `0px 1px 3px 1px ${COLORS.cozmosBlue}`
      },
      '&.selected .MuiCheckbox-root': {
        display: 'block'
      }
    },
    '&.preview': {
      position: 'relative',
      pointerEvents: 'auto',
      '&:hover .image-block img': {
        filter: 'blur(1px)',
      },
      '&:hover input, &:hover path': {
        cursor: 'pointer',
      },
      '& .cursor-default:hover path': {
        cursor: 'default',
      },
      '&:hover span, &:hover .on-hover-button': {
        display: 'block',
      },
      '&:hover .no-display-on-hover': { display: 'none' },
      '& .max-height': { maxHeight: size.small },
    },
    '& .on-hover-button': { display: 'none', marginLeft: 'auto' },
    '& .on-hover-button:hover': { color: theme.palette.primary.main },
    '& .active': { color: theme.palette.primary.main },
    '& .MuiButtonBase-root': {
      backgroundColor: COLORS.white,
      opacity: 0.8,
      minHeight: 'auto',
      borderRadius: '2px',
      padding: '1px',
      '& .MuiTouchRipple-root, & .MuiIconButton-label': { minHeight: 'auto' },
      '& .MuiIconButton-label': { height: '24px' },
      '&.sphere-button': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: '20px',
        ...theme.typography.h5,
        padding: '0',
        minWidth: '64px',
        margin: '4px',
      },
    },
    '& .absolute': {
      position: 'absolute',
      zIndex: '11',
      cursor: 'pointer',
      '&.top': { top: '6px' },
      '&.right': { right: '6px' },
      '&.bottom': { bottom: '6px' },
      '&.left': { left: '6px' },
    },
    '&.small': {
      '& .preview': {
        margin: '6px!important',
        '& .MuiButtonBase-root': { padding: '4px' },
        '& .image-block': {
          width: `${size.small}`,
          height: `${size.small}`,
        },
      },
      '& .file-name': {
        maxWidth: size.small,
        margin: '4px 6px',
      },
    },
    '&.file-name': {
      ...theme.typography.caption,
      maxWidth: size.regular,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      margin: '4px 0',
      borderRadius: '2px',
      '&.seo': { color: COLORS.greyDefault },
    },
    '&.image-block': {
      border: `1px solid ${COLORS.greyBorder}`,
      borderRadius: '5px',
      background: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      width: size.regular,
      height: size.regular,
    },
    '& .status-icon': {
      position: 'absolute',
      bottom: '6px',
      right: '6px',
      zIndex: '11',
      '&.success, &.generated': { color: COLORS.success },
    },
  },
});

export const Box = withStyles(styles)(MuiBox);

export const Checkbox = withStyles({
  root: {
    display: 'none',
  },
})(MuiCheckbox);
