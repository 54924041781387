import config from 'config/Config';
import {
  CloneRequestParams,
  CloneRequestResponse,
  OmniAuthTokenParams,
  SetPasswordParams,
  SignInParams,
  SignUpParams,
  SocialSignInParams,
  SocialSignUpParams,
  UserAttributes,
  VerifyEmailTokenParams,
} from 'types/user';
import { getHeadersFromLocalStorage, setHeadersToLocalStorage } from 'utils';
import { buildUrlWithQuery } from 'utils/helpers/urlHelpers';

import { api } from './setupApi';

export enum AuthModelFields {
  SPACE = 'clientName',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PASSWORD = 'password',
  PASSWORD_CONFIRMATION = 'passwordConfirmation',
  TERMS = 'terms',
  HCAPTCHA = 'hCaptchaResponse',
  TOKEN = 'token',
}

export enum GoogleFlow {
  SIGN_IN = 'signin',
  SIGN_UP = 'signup',
  INVITE = 'invite',
}

class AuthModel {
  link = '/auth';

  validation = `${this.link}/validate_token`;

  signInLink = `${this.link}/sign_in`;

  signUpLink = `${this.link}/sign_up`;

  resendEmailLink = `${this.signUpLink}/resend_email`;

  resendEmailTimoutLink = `${this.signUpLink}/resend_email_timeout`;

  verifyEmailTokenLink = `${this.signUpLink}/verify_email_token`;

  setPasswordLink = `${this.signUpLink}/set_password`;

  listAccountsLink = `${this.link}/omniauth/list_accounts`;

  socialSignInLink = `${this.link}/omniauth/login_as_user`;

  socialSignUpLink = `${this.link}/omniauth/sign_up`;

  confirmCloningLink = `${this.link}/confirm_cloning`;

  declineCloningLink = `${this.link}/decline_cloning`;

  fields = {
    space: 'clientName',
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    password: 'password',
    passwordConfirmation: 'passwordConfirmation',
    terms: 'terms',
    hCaptcha: 'hCaptchaResponse',
    token: 'token',
  };

  googleFlow = {
    signIn: 'signin',
    signUp: 'signup',
    invite: 'invite',
  };

  signIn = (dataToSend: SignInParams): Promise<UserAttributes> =>
    api.post<Record<'data', UserAttributes>>(this.signInLink, dataToSend).then(({ data: { data }, headers }) => {
      setHeadersToLocalStorage(headers);
      return data;
    });

  signOut = () => api.delete(`${this.link}/sign_out`).then(({ data }) => data);

  verifyToken = (): Promise<UserAttributes> =>
    api
      .get<Record<'data', UserAttributes>>(buildUrlWithQuery(this.validation, getHeadersFromLocalStorage()))
      .then(({ data: { data } }) => data);

  signUp = (dataToSend: SignUpParams) => api.post(this.signUpLink, dataToSend).then(({ data }) => data);

  resendEmail = (dataToSend: VerifyEmailTokenParams) =>
    api.post(`${this.resendEmailLink}`, dataToSend).then(({ data }) => data);

  getResendEmailTimeout = (dataToSend: VerifyEmailTokenParams) =>
    api.post(`${this.resendEmailTimoutLink}`, dataToSend).then(({ data }) => data);

  verifyEmailToken = (dataToSend: VerifyEmailTokenParams) =>
    api.post(`${this.verifyEmailTokenLink}`, dataToSend).then(({ data }) => data);

  setPassword = (dataToSend: SetPasswordParams) =>
    api.post(`${this.setPasswordLink}`, dataToSend).then(({ data }) => data);

  redirectToGoogleAuth = (flow: string = this.googleFlow.signIn, token?: string) => {
    window.location.href = `${config.api_host}/auth/google?flow=${flow}&token=${token}`;
  };

  socialSignUp = (params: SocialSignUpParams) => api.post(this.socialSignUpLink, params).then(({ data }) => data);

  socialSignIn = (params: SocialSignInParams) => api.post(this.socialSignInLink, params).then(({ data }) => data);

  listAccounts = (params: OmniAuthTokenParams) => api.post(this.listAccountsLink, params).then(({ data }) => data);

  confirmCloning = (params: CloneRequestParams): Promise<CloneRequestResponse> =>
    api.post<CloneRequestResponse>(this.confirmCloningLink, params).then(({ data }) => data);

  declineCloning = (params: CloneRequestParams): Promise<CloneRequestResponse> =>
    api.post<CloneRequestResponse>(this.declineCloningLink, params).then(({ data }) => data);
}

export const authModel = new AuthModel();
