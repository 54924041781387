import React, { ReactNode } from 'react';

import { compose } from 'lodash/fp';
import { Route } from 'react-router-dom';
import { withSentryRouting } from '@sentry/react';

import { withAuthLayout, withDashboard, withErrorHandling, withSignIn } from '../utils/enhancers';

import { PUBLIC_ROUTES, ROUTES_WITHOUT_DASHBOARD } from './constants';

const SentryRoute = withSentryRouting(Route);

interface RouteComponentProps {
  path?: string;
  component: ReactNode;
  exact?: boolean;
}

export function RouteComponent({ path = '', component, exact = true }: RouteComponentProps) {
  const enhancers: any[] = [withErrorHandling];

  switch (true) {
    case PUBLIC_ROUTES.includes(path):
      enhancers.push(withAuthLayout);
      break;
    case ROUTES_WITHOUT_DASHBOARD.includes(path):
      enhancers.push(withSignIn);
      break;
    default:
      enhancers.push(withDashboard, withSignIn);
  }

  return <SentryRoute path={path} exact={exact} component={compose(...enhancers)(component)} />;
}

RouteComponent.defaultProps = { exact: true, path: '' };
