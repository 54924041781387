import React, { createContext, PropsWithChildren, useReducer } from 'react';

import { DEFAULT_FUNC } from 'constants/technical';
import { activeStorageModel } from 'models';
import { ActiveStorageBlob, DataServerErrors, ErrorType } from 'types/common';
import { FileWithUploadData } from 'types/images';
import { getServerError, getTWithPathKey } from 'utils';
import { SECTIONS_WITH_CAPTCHA } from 'utils/captcha';
import { generateContextHook } from 'utils/helpers/contextHelpers';

import { UPLOAD_STATUSES } from './constants';
import reducer from './reducer';
import { UploadActionTypes, UploadStatusDispatchType, UploadStatusStateType } from './types';

const initialState: UploadStatusStateType = {
  uploads: [],
  delayResetIds: [],
  errors: {},
  requestStatuses: new Map(),
};

const UploadStatusStateContext = createContext<UploadStatusStateType>(initialState);
const UploadStatusDispatchContext = createContext<UploadStatusDispatchType>({
  createUpload: DEFAULT_FUNC,
  addUpload: DEFAULT_FUNC,
  addS3Request: DEFAULT_FUNC,
  updateUpload: DEFAULT_FUNC,
  failUpload: DEFAULT_FUNC,
  completeUpload: DEFAULT_FUNC,
  resetAllUploads: DEFAULT_FUNC,
  resetErrors: DEFAULT_FUNC,
  setUploadStatus: DEFAULT_FUNC,
  handleUploadError: DEFAULT_FUNC,
  handleUploadRequest: DEFAULT_FUNC,
  resetUploadStatuses: DEFAULT_FUNC,
});

const UploadStatusProvider = <T,>({ children }: PropsWithChildren<T>) => {
  const getT = (key: string) => getTWithPathKey('errorsTexts')(key);
  const [state, dispatch] = useReducer(reducer, initialState);

  const dispatchValue: UploadStatusDispatchType = {
    createUpload: (file: FileWithUploadData, section: string, cb = () => Promise.resolve()) => {
      const id = file.upload.uuid;
      const name = file.upload.filename;

      dispatchValue.addUpload({ id, name, section });
      dispatchValue.setUploadStatus(file, UPLOAD_STATUSES.UPLOAD);

      activeStorageModel
        .createUpload(file, (request: XMLHttpRequest) => dispatchValue.addS3Request({ request, id }))
        .create((error, blob) => {
          let modifiedError: DataServerErrors<ErrorType<string>[]> | null = null;

          if (error && error.message) {
            modifiedError = getServerError(error.message);

            if (error.message.includes('Blob') && SECTIONS_WITH_CAPTCHA.includes(section)) {
              modifiedError.response.data.errors.base.push(`\n${getT('wrongCaptcha')}`);
            }
          }

          const request = cb(modifiedError, blob as ActiveStorageBlob);

          dispatchValue.handleUploadRequest(request, file, section);
        });
    },

    addUpload: data => dispatch({ type: UploadActionTypes.ADD_UPLOAD, payload: data }),
    addS3Request: ({ request, id }) => {
      request.upload.addEventListener('progress', ({ loaded, total }) =>
        dispatchValue.updateUpload({ id, loaded, total, request }),
      );
      request.upload.addEventListener('loadend', () => setTimeout(() => dispatchValue.completeUpload(), 1000));
      request.upload.addEventListener('error', () => dispatchValue.failUpload(id));
    },

    updateUpload: data => dispatch({ type: UploadActionTypes.UPDATE_UPLOAD, payload: data }),
    completeUpload: () => dispatch({ type: UploadActionTypes.COMPLETE_UPLOAD }),
    failUpload: uuid => dispatch({ type: UploadActionTypes.FAIL_UPLOAD, payload: uuid }),
    resetAllUploads: () => dispatch({ type: UploadActionTypes.RESET_ALL }),
    setUploadStatus: (file, status) =>
      dispatch({ type: UploadActionTypes.SET_UPLOAD_STATUS, payload: { file, status } }),

    handleUploadError: ({ error, file, section }) =>
      dispatch({
        type: UploadActionTypes.HANDLE_UPLOAD_ERROR,
        payload: { error, file, section },
      }),

    resetErrors: () => dispatch({ type: UploadActionTypes.RESET_ERRORS }),

    handleUploadRequest: (request, file, section) => {
      dispatchValue.setUploadStatus(file, UPLOAD_STATUSES.PENDING);
      return request
        .then(() => {
          dispatchValue.setUploadStatus(file, UPLOAD_STATUSES.SUCCESS);
        })
        .catch(error => {
          dispatchValue.handleUploadError({ error, file, section });
          dispatchValue.setUploadStatus(file, UPLOAD_STATUSES.ERROR);
        });
    },

    resetUploadStatuses: () => dispatch({ type: UploadActionTypes.RESET_UPLOAD_STATUSES }),
  };

  return (
    <UploadStatusStateContext.Provider value={state}>
      <UploadStatusDispatchContext.Provider value={dispatchValue}>{children}</UploadStatusDispatchContext.Provider>
    </UploadStatusStateContext.Provider>
  );
};

const useUploadStatusState = generateContextHook(UploadStatusStateContext, 'UploadStatus');

const useUploadStatusActions = generateContextHook(UploadStatusDispatchContext, 'UploadStatus');

export { UploadStatusProvider, useUploadStatusState, useUploadStatusActions };
