import { PaginationParams } from 'types/common';
import { PicturesTagParams, Tag, TagsListResponse, TagsSearchParams } from 'types/images';
import { trimItems } from 'utils';

import { api } from './setupApi';

class TagsModel {
  link = '/tags';

  attachAssetsLink = '/tags/attach_records';

  linkRemoveAsset = '/tags/detach_records';

  getTagsWithOrder = ({ page, perPage }: PaginationParams): Promise<TagsListResponse> =>
    api
      .get<TagsListResponse>(`${this.link}?order_by=created_at&order_dir=desc&page=${page}&per_page=${perPage}`)
      .then(({ data }) => data);

  getTagsWithParams = (params: TagsSearchParams): Promise<TagsListResponse> =>
    api.get<TagsListResponse>(this.link, { params }).then(({ data }) => data);

  addTag = (name: string, color: string) =>
    api.post(this.link, trimItems({ name, color }, ['name'])).then(({ data: { tag } }) => tag);

  editTag = (id: number, data: Pick<Tag, 'name' | 'color'>) =>
    api.put(`${this.link}/${id}`, trimItems(data, ['name'])).then(({ data: { tag } }) => tag);

  attachAssetsTag = (data: PicturesTagParams) =>
    api.put(`${this.attachAssetsLink}`, data).then(({ data: { tag } }) => tag);

  deleteTag = (id: number) => api.delete(`${this.link}/${id}`);

  deleteAsset = (data: PicturesTagParams) => api.put(`${this.linkRemoveAsset}`, data);

  getAttachedAssets = (id: number | undefined, params: TagsSearchParams): Promise<Tag> =>
    api.get(`${this.link}/${id}`, { params }).then(({ data }) => data);
}

export const tagsModel = new TagsModel();
