import { styled, Typography } from '@material-ui/core';

// Zeplin typography styles https://app.zeplin.io/project/63fe28513f922b4000fbd1eb/styleguide/textstyles

export const HeadingL = styled(Typography)({
  fontSize: '20px',
  lineHeight: 1.2,
  letterSpacing: '0.2px',
});

export const BodyS = styled(Typography)({
  fontSize: '14px',
  lineHeight: 1.43,
  letterSpacing: '0.14px',
});
