import { withStyles } from '@material-ui/core';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';

export const Autocomplete = withStyles(theme => ({
  root: {
    '& .MuiInputBase-root': {
      ...theme.typography.caption,
      fontStyle: 'italic',
    },
    '& .MuiFormLabel-root': {
      ...theme.typography.caption,
    },
    '&.Mui-focused *, .MuiInputBase-root:hover *': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '&.tag-autocomplete': {
      margin: 0,
    },
    '&.tag-autocomplete .MuiFormControl-root': {
      margin: 0,
    },
    '&.tag-autocomplete .MuiInputBase-root': {
      padding: '3px 39px 3px 6px',
    },
    '&.tag-autocomplete .MuiFormLabel-root': {
      transform: 'translate(14px, 8px) scale(1)',
    },
    '&.tag-autocomplete .MuiFormLabel-root.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '&.theme-select': {
      '& .MuiInputBase-root': {
        ...theme.typography.caption,
        fontStyle: 'normal',
        fontWeight: 500,
      },
    },
  },
}))(MuiAutocomplete);
