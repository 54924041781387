import React from 'react';

import PropTypes from 'prop-types';

import { Box, Dot } from './style';

export function DotsComponent({ dotsColors, className }) {
  return (
    <Box className={className}>
      {dotsColors.map(dotColor => (
        <Dot key={dotColor} style={{ backgroundColor: `${dotColor}` }} />
      ))}
    </Box>
  );
}

DotsComponent.propTypes = {
  dotsColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
};

DotsComponent.defaultProps = {
  className: '',
};
