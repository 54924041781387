import React, { DragEvent } from 'react';

import { CircularProgress, IconButton } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import classnames from 'classnames';
import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { AccessibilityLabelIcon } from 'components/CustomIcons';
import { DotsComponent } from 'components/DotsComponent/DotsComponent';
import { DEFAULT_FUNC } from 'constants/technical';
import { getImagePreviewByStatus } from 'utils';
import { ImageStatus, ImageWithInlineStyles } from 'types/images';
import { VideoAsset } from 'types/other';
import { ReactComponent as CopyIcon } from 'assets/icons/system/copy.svg';

import { ButtonComponent } from '../ButtonComponent/ButtonComponent';
import { IconWithConfirmation } from '../IconWithConfirmation/IconWithConfirmation';
import { ERRORS_STATUSES, IN_PROGRESS_STATUSES } from '../DropzoneImage/constants';
import { EditImageIconWithDialog } from '../DropzoneImage/EditImageIconWithDialog/EditImageIconWithDialog';

import { Box, Checkbox } from '../DropzoneImage/style';

export interface DropzoneVideoProps {
  image: ImageWithInlineStyles;
  status: ImageStatus | null;
  smallView?: boolean;
  withEdit?: boolean;
  withInfo?: boolean;
  withRemove?: boolean;
  withUseOnSphere?: boolean;
  handleEdit?: (imageId: number, image: ImageWithInlineStyles) => void;
  handleInfo?: (image: ImageWithInlineStyles) => void;
  onRemoveImage?: (id: number) => void;
  withLink?: boolean;
  withCheckbox: boolean;
  selectedImages?: number[];
  onChangeSelected?: (id: number) => void;
  withTags?: boolean;
}

export const DropzoneVideo = ({
  image,
  onRemoveImage = DEFAULT_FUNC,
  withRemove = false,
  smallView = false,
  withEdit = false,
  handleEdit = DEFAULT_FUNC,
  status,
  withUseOnSphere = false,
  withInfo = false,
  handleInfo = DEFAULT_FUNC,
  withLink = true,
  withCheckbox,
  onChangeSelected,
  selectedImages,
  withTags,
}: DropzoneVideoProps): JSX.Element => {
  const video = image as VideoAsset;
  const onDragStart = (id: number) => (event: DragEvent) => event.dataTransfer.setData('pictureId', String(id));
  const { t } = useTranslation();

  const getDotsColors = () => get(video, 'tags', []).map(tag => tag.color);

  const showCheckbox = withUseOnSphere && video.usedOnPlanogram;

  const isProcessing = !video.curatorReady;

  const showTags = withTags && !isEmpty(getDotsColors());

  const renderStatusIcon = (): JSX.Element | null => {
    if (!status) {
      if (isProcessing) {
        return <CircularProgress size={24} className="status-icon" />;
      }

      return null;
    }

    const className = `status-icon ${status}`;

    if (status === ImageStatus.GENERATED) {
      return <CheckCircleIcon className={className} />;
    }
    if (ERRORS_STATUSES.includes(status)) {
      return <ErrorIcon color="error" className={className} />;
    }
    if (IN_PROGRESS_STATUSES.includes(status)) {
      return <CircularProgress size={24} className={className} />;
    }

    return null;
  };

  const onCheckedChange = () => onChangeSelected?.(image.id);

  const isChecked = selectedImages?.find(id => id === image.id) !== undefined;

  const onImageBlockClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation();

  const showAccessibilityIcon = !withInfo && withEdit && !video.accessibilityDescription;
  const showRemoveIcon = withRemove && !video.isSystem;

  const copyVideoLink = () => navigator.clipboard.writeText(video.url);

  return (
    <Box
      className={classnames('preview-block file-preview', {
        small: smallView,
        selected: isChecked
      })}
      onClick={onImageBlockClick}
    >
      <Box className="preview">
        {!isProcessing && (
          <>
            {showRemoveIcon && (
              <IconWithConfirmation
                buttonClassName="top right absolute on-hover-button"
                message="video"
                value={video.id}
                handleConfirm={onRemoveImage}
              />
            )}
            {withCheckbox && (
              <Checkbox
                className="top left absolute"
                color="primary"
                checked={isChecked}
                onChange={onCheckedChange}
              />
            )}
            {withEdit && (
              <EditImageIconWithDialog
                className="bottom right absolute on-hover-button"
                image={video}
                handleEdit={handleEdit}
              />
            )}
            {withInfo && (
              <IconButton className="bottom right absolute on-hover-button" onClick={() => handleInfo(video)}>
                <InfoOutlinedIcon />
              </IconButton>
            )}
            {showAccessibilityIcon && (
              <Box className="absolute bottom right cursor-default no-display-on-hover">
                <AccessibilityLabelIcon />
              </Box>
            )}
            {showTags && (
              <DotsComponent dotsColors={getDotsColors()} className="no-display-on-hover max-height top right absolute" />
            )}
          </>
        )}

        {renderStatusIcon()}
        <Box className="image-block" draggable="true" onDragStart={onDragStart(video.id)}>
          <video
            style={{ objectFit: 'contain', width: '100%', height: '100%' }}
            controls={false}
            preload="metadata"
            data-dz-thumbnail
            poster={isProcessing ? getImagePreviewByStatus({status: ImageStatus.PENDING} as any) : undefined}
            src={video.url}
          />
        </Box>
      </Box>
      <Box className="file-name">{video.imageName}</Box>
      {!!video.seoTitle && <Box className="file-name seo">{video.seoTitle}</Box>}
      {withLink && !isProcessing && (
        <ButtonComponent
          text={t('components.dropzoneVideo.copyVideoLink')}
          variant="text"
          className="blue ml"
          Icon={CopyIcon}
          onClick={copyVideoLink}
        />
      )}
    </Box>
  );
};
