import React, { useEffect, useState } from 'react';

import { Box, LinearProgress, Typography } from '@material-ui/core';
import { keys } from 'lodash';
import { useTranslation } from 'react-i18next';

import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useUploadStatusActions, useUploadStatusState } from 'contexts/UploadStatusContext';
import {
  getProgress,
  getProgressBySections,
  getTotalProgress,
  createComplexUploadError,
  hasUnfinishedRequests,
} from 'utils';

import { IconWithConfirmation } from '../IconWithConfirmation/IconWithConfirmation';

import { BoxContainer, CloseWrapBox, CloseIcon, CircularProgress, DoneIcon } from './style';

export function UploadStatusBar() {
  const { t } = useTranslation();
  const { uploads, errors, requestStatuses } = useUploadStatusState();
  const { resetAllUploads, resetErrors, resetUploadStatuses } = useUploadStatusActions();
  const { handleServerError } = useServerErrorActions();

  const [isReady, setIsReady] = useState(false);

  const totalProgress = getTotalProgress(uploads);
  const progressBySections = getProgressBySections(uploads);

  useEffect(() => {
    if (requestStatuses.size && !hasUnfinishedRequests(requestStatuses)) {
      handleServerError(createComplexUploadError(errors));
      setIsReady(true);
      resetErrors();
      resetUploadStatuses();
    } else {
      setIsReady(false);
    }
  }, [requestStatuses]);

  const renderProgresses = sectionProgress => {
    const progress = getProgress(sectionProgress);

    return progress ? <span>{`${progress}%`}</span> : <CircularProgress />;
  };

  const renderItem = (section, sectionState) => (
    <Box key={section}>
      <Typography variant="h4" display="inline">
        {`${t(`utils.upload.section.${section}`)} `}
      </Typography>
      <Typography variant="caption" style={{ display: 'inline-flex' }}>
        {sectionState.loadedCount} of {sectionState.totalCount} - {renderProgresses(sectionState)}
      </Typography>
    </Box>
  );

  const renderInProgressState = () => (
    <BoxContainer>
      {keys(progressBySections).map(section => renderItem(section, progressBySections[section]))}
      <Box mt={3}>
        <LinearProgress
          color="primary"
          variant={totalProgress ? 'determinate' : 'indeterminate'}
          value={totalProgress}
        />
      </Box>
      <CloseWrapBox>
        <IconWithConfirmation
          message={t('components.uploadStatusBar.confirmation')}
          handleConfirm={resetAllUploads}
          Component={CloseIcon}
          confirmText="Reset"
          defaultMessage={false}
        />
      </CloseWrapBox>
    </BoxContainer>
  );

  const renderSuccessState = () => (
    <BoxContainer className="success">
      <Box>
        <Box mr={2}>
          <DoneIcon />
        </Box>
        <Typography variant="caption">{t('components.uploadStatusBar.success')}</Typography>
      </Box>
    </BoxContainer>
  );

  if (!uploads.length) {
    return null;
  }
  return isReady ? renderSuccessState() : renderInProgressState();
}
