import React, { memo, useEffect, useState } from 'react';

import { Box, CircularProgress } from '@material-ui/core';
import { get } from 'lodash';
import { compose } from 'lodash/fp';

import { DOWNLOAD_SECTIONS } from 'contexts/UploadStatusContext/constants';
import { ACCEPT_EXTENSIONS } from 'components/ImageUploader/constants';
import { DropzoneVideo } from 'components/DropzoneVideo/DropzoneVideo';
import { ImageUploader } from 'components/ImageUploader/ImageUploader';
import { withPagination } from 'utils/enhancers';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { picturesModel, tagsModel, videosModel } from 'models';
import { FileWithSignedId, Image, PaginationProps, Tag, UploadedPicture } from 'types';
import { useSubmittingState } from 'hooks';

import { Backdrop } from '../CardComponent/style';

interface TagAssetsProps {
  tag: Tag;
  handleServerError: (err: any) => void;
}

const isVideoType = new RegExp('video/mp4');

const TagAssetsComponent = ({
  paginationParams,
  setPaginationItemsCount,
  resetPaginationPage,
  tag,
  handleServerError
}: PaginationProps & TagAssetsProps) => {
  const { isSubmitting, withHandlingSubmittingState } = useSubmittingState();
  const [assets, setAssets] = useState<Map<number, Image>>(new Map());
  const { withPageProgressHandler } = useBusyProgressActions();

  useEffect(() => {
    fetchAssets();
  }, [paginationParams]);

  const fetchAssets = withHandlingSubmittingState(() => (
    tagsModel
      .getAttachedAssets(tag.id, { ...paginationParams })
      .then(data => {
        assets.clear();
        data.taggingRecords?.forEach(asset => assets.set(asset.id, asset));
        setAssets(assets);
        setPaginationItemsCount(data.taggingRecordsCount ?? 0);
      })
      .catch(handleServerError)
  ));

  const addImagesToTag = (tagId: number | undefined, assets: number[]) => (
    tagsModel.attachAssetsTag({
        id: tagId,
        tag: {
          pictureIds: assets,
        }
      })
      .then(resetPaginationPage)
      .catch(handleServerError));

  const addVideosToTag = (tagId: number | undefined, assets: number[]) => (
    tagsModel.attachAssetsTag({
      id: tagId,
      tag: {
        videoIds: assets,
      }
    })
      .then(resetPaginationPage)
      .catch(handleServerError));

  const parseAssets = (assetIds: number[]) => {
    const images: number[] = [];
    const videos: number[] = [];

    assetIds.forEach(id => {
      const asset = assets.get(id);

      if (asset && isVideoType.test(asset.contentType)) {
        videos.push(asset.id);
      } else if (asset) {
        images.push(asset.id);
      }
    });

    return {
      images,
      videos
    }
  }

  const loadAsset = (asset: FileWithSignedId) => {
    const signedId = get(asset, 'signed_id');

    return isVideoType.test(asset.type) ?
      videosModel.createVideo({
        seoDesc: '',
        seoTitle: asset.name,
        h1Tag: '',
        signedId,
      })
        .then(video => {
          addVideosToTag(tag.id, [video.id])
        })
        .catch(handleServerError) :
      picturesModel.addPicture({ signedId })
        .then(picture => {
          addImagesToTag(tag.id, [picture.id]);
        })
        .catch(handleServerError);
  };

  const onRemoveTagFromAsset = (selectedAssetsIds: number[]) => {
    const assets = parseAssets(selectedAssetsIds);

    tagsModel.deleteAsset({
      id: tag.id,
      tag: {
        pictureIds: [...assets.images],
        videoIds: [...assets.videos],
      }
    })
      .then(() => {
        resetPaginationPage();
      })
      .catch(handleServerError);
  }

  const handleDeleteAllAssets = withPageProgressHandler((assetsIds: number[]) => {
    const assets = parseAssets(assetsIds);
    const imagesActions = assets.images.map(id =>
      picturesModel
        .deletePicture(id)
        .catch(handleServerError)
    );
    const videosActions = assets.videos.map(id =>
      videosModel
        .deleteVideo(id)
        .catch(handleServerError)
    );

    return Promise.allSettled([...imagesActions, ...videosActions]).then(resetPaginationPage);
  });

  const unloadAsset = (id: number) => {
    const assetType = assets.get(id)?.contentType ?? '';

    if (isVideoType.test(assetType)) {
      return videosModel
        .deleteVideo(id)
        .then(resetPaginationPage)
        .catch(handleServerError)
    }

    return picturesModel
      .deletePicture(id)
      .then(resetPaginationPage)
      .catch(handleServerError)
  };

  const handleEdit = (id: number, data: UploadedPicture) => {
    const assets = parseAssets([id]);

    if (assets.videos.length > 0) {
      return videosModel
        .updateVideo(id, {
          seoDesc: data.accessibilityDescription ?? '',
          seoTitle: data.seoTitle ?? data.imageName,
        })
        .then(resetPaginationPage)
        .catch(handleServerError)
    }

    return picturesModel
      .editPicture(id, data)
      .then(resetPaginationPage)
      .catch(handleServerError)
  };

  return (
    <>
      <Backdrop open={isSubmitting}>
        <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center">
          <CircularProgress size={40} thickness={4} />
        </Box>
      </Backdrop>
      <ImageUploader
        images={Array.from(assets.values())}
        loadImage={loadAsset}
        section={DOWNLOAD_SECTIONS.images}
        activeTag={tag}
        unloadImage={unloadAsset}
        selectButtonText="Detach from Tag"
        handleSelectComplete={onRemoveTagFromAsset}
        handleDeleteAll={handleDeleteAllAssets}
        handleServerError={handleServerError}
        withEdit
        withTags
        handleEdit={handleEdit}
        withUploadButton
        showAccessibilityHint={false}
        uploadButtonText="Upload PNG/JPG/MP4"
        text=""
        accept={ACCEPT_EXTENSIONS.imgAndVideo}
        fallbacks={{ 'video/mp4': DropzoneVideo }}
      />
    </>
  );
}

export const TagAssets = memo(compose(withPagination)(TagAssetsComponent));
