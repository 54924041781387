import { styled, Box as MuiBox } from '@material-ui/core';

export const Box = styled(MuiBox)({
  '&#arrows-wrapper': {
    display: 'flex',
    justifyContent: 'center'
  },
  '&.arrow-button': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '72px',
    height: '72px',
    borderRadius: '50%',
    overflow: 'hidden',
  },
  '&.arrow-button-icon-container': {
    display: 'flex',
    justifyContent: 'center',
  },
  '&.arrow-button-icon-container img': {
    width: '100%',
    height: 'auto',
    objectFit: 'contain',
  },
});
