import React, { useEffect, createContext, useContext, ReactNode, useMemo, useRef, useState, useReducer } from 'react';

import { shoppingModel } from 'models';
import { ShoppingProviderStatus } from 'types';

import { useAuthState } from './AuthContext';

interface ShoppingProviderContextType {
  syncStatus: ShoppingProviderStatus | null;
  isBlocked: boolean;
  refresh: () => Promise<void>;
  block: () => void;
  unblock: () => void;
}

export const ShoppingProviderContext = createContext<ShoppingProviderContextType>({
  syncStatus: null,
  isBlocked: false,
  refresh: () => Promise.resolve(),
  block: () => {},
  unblock: () => {},
});

export function ShoppingProviderStatusProvider({ children }: { children: ReactNode }) {
  const [syncStatus, setSyncStatus] = useState<ShoppingProviderStatus | null>(null);

  const { currentUser } = useAuthState();

  async function getProviderStatus(): Promise<ShoppingProviderStatus | null> {
    const shopifyProviderId = 1; // TODO: support multiple providers

    try {
      const provider = await shoppingModel.getShoppingProvider(shopifyProviderId);

      return provider.clientShoppingProvider?.syncStatus ?? ShoppingProviderStatus.idle;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return ShoppingProviderStatus.idle;
    }
  }

  function refresh() {
    return getProviderStatus().then(setSyncStatus);
  }

  useEffect(() => {
    setSyncStatus(null);
  }, [currentUser.isSignedIn, currentUser.attributes.id]);

  useEffect(() => {
    if (!currentUser.isSignedIn) return () => {};

    if (syncStatus === null) {
      getProviderStatus().then(setSyncStatus);
    }

    const second = 1000;
    const delay = second * (context.syncStatus === ShoppingProviderStatus.disconnectInProgress ? 10 : 60);
    const interval = setInterval(() => getProviderStatus().then(setSyncStatus), delay);

    return () => clearInterval(interval);
  }, [syncStatus, currentUser.isSignedIn, currentUser.attributes.id]);

  const [blockers, changeBlockers] = useReducer((state: number, action: number) => state + action, 0);

  function block() {
    changeBlockers(1);
  }

  function unblock() {
    changeBlockers(-1);
  }

  const context = useMemo<ShoppingProviderContextType>(
    () => ({
      syncStatus,
      refresh,
      isBlocked: blockers > 0,
      block,
      unblock,
    }),
    [syncStatus, blockers],
  );

  return <ShoppingProviderContext.Provider value={context}>{children}</ShoppingProviderContext.Provider>;
}

export function useShoppingProviderBlocker() {
  const context = useContext(ShoppingProviderContext);

  useEffect(() => {
    context.block();
    return () => context.unblock();
  }, []);

  const knownStatus = context.syncStatus !== null;

  return knownStatus;
}

export function useShoppingProviderStatus() {
  const context = useContext(ShoppingProviderContext);

  const shouldBlock = context.syncStatus === ShoppingProviderStatus.disconnectInProgress || context.syncStatus === null;

  const wasInProgress = useRef(false);
  const [result, setResult] = useState<boolean | null>(null);
  const isInProgress = context.syncStatus === ShoppingProviderStatus.disconnectInProgress;
  const hasFailed = context.syncStatus === ShoppingProviderStatus.disconnectFailed;

  if (!wasInProgress.current && isInProgress) setResult(null);
  if (hasFailed && result !== false) setResult(false);
  if (wasInProgress.current && !isInProgress) setResult(!hasFailed);

  wasInProgress.current = isInProgress;

  return {
    status: context.syncStatus,
    shouldBlock,
    isBlocked: context.isBlocked,
    result,
    refreshStatus: context.refresh,
  };
}
