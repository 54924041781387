import { Box as MuiBox, styled } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const Box = styled(MuiBox)({
  borderRadius: '6px',
  padding: '2px 14px',
  lineHeight: 1,
  fontWeight: '500',
  alignSelf: 'baseline',
  minHeight: '24px',
  display: 'flex',
  alignItems: 'center',
  flex: 'none',
  '&.violet': {
    backgroundColor: COLORS.violetLight,
    color: COLORS.violetDeep,
  },
  '&.orange': {
    backgroundColor: COLORS.orangeBg,
    color: COLORS.orange,
  },
  '&.green': {
    backgroundColor: COLORS.greenLight,
    color: COLORS.greenDeep,
  },
  '&.systemInfo': {
    backgroundColor: COLORS.systemInfo5,
    color: COLORS.systemInfo60,
    border: `1px solid ${COLORS.systemInfo30}`,
  },
  '&.primary': {
    backgroundColor: COLORS.orangeBg,
    color: COLORS.orange,
  },
  '&.failed': {
    backgroundColor: COLORS.verificationFailed,
    color: COLORS.verificationFailedText,
  },
  '&.error': {
    backgroundColor: COLORS.redLight,
    color: COLORS.red,
  },
  '&.sync-failed': {
    backgroundColor: COLORS.cozmosRedLight,
    color: COLORS.cozmosRed,
  },
  '&.with-border': {
    border: '1px solid',
    padding: '0 14px',
  },
});
