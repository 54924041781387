export const defaultValues = [
  'id',
  'imageSize',
  'controlButton',
  'elementType',
  'title',
  'planogramVersionControlButtonFont',
  'showTitle',
  'showShadow',
];

export const controlSettingsValues = [
  ...defaultValues,
  'navigationValueSelect',
  'navigationValueUrl',
  'navigationType',
  'navigationValue',
  'openInNewPage',
];

export const menuSettingsValues = [...defaultValues, 'showMenuItemsShadow', 'menu'];

export const searchSettingsValues = [
  ...defaultValues,
  'searchActiveIcon',
  'searchSettingFont',
  'noResultSearchSettingFont',
  'searchSetting',
];

export const internalSearchSettingsValues = [
  'allowedExcludePlanogramVersion',
  'allowedSearchAttributes',
  'allowedSearchOrderDir',
  'searchAttributes',
  'searchOrderBy',
  'searchOrderDir',
  'searchTitle',
  'searchSettingFont',
  'excludePlanogramIds',
  'searchActiveIcon',
  'noResultSearchSettingFont',
  'noResultSearchTitle',
  'controlButton',
  'otherAsset',
];
