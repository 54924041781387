import {
  PaginationParams,
  ECommerceOverlaySettings,
  SearchSettings,
  SharingButton,
  PlanogramExtended,
  PlanogramInfo,
  PlanogramListResponse,
  PlanogramSharingButtonResponse,
  PlanogramUpdateParams,
  PlanogramVersion,
  PlanogramVersionUpdateParams,
  PlanogramCloneParams,
  Planogram,
  PlanogramVersionUpdateResponse,
  PlanogramObjectsResponse,
} from 'types';
import { trimItems } from 'utils';

import { AnimationPath, AnimationPathResponse, NavigationArrow } from '../types/other';

import { api } from './setupApi';

class PlanogramsModel {
  link = '/planograms';

  subLink = '/planogram_infos';

  versions = '/planogram_versions';

  downloadPng = '/download_png';

  downloadSvg = '/download_svg';

  fontSettings = '/planogram_info_fonts';

  shareButtons = '/sharing_buttons';

  searchSettings = '/search_settings';

  ecommerceSettings = '/ecommerce_overlay_settings';

  animationPath = '/animation_paths';

  getPlanograms = (params: PaginationParams): Promise<PlanogramListResponse> =>
    api.get<PlanogramListResponse>(this.link, { params }).then(({ data }) => data);

  getPlanogram = (id: number): Promise<Planogram> => api.get<Planogram>(`${this.link}/${id}`).then(({ data }) => data);

  addPlanogram = (data: PlanogramUpdateParams) => api.post(this.link, trimItems(data, ['name']));

  editPlanogram = (id: number, data: PlanogramUpdateParams): Promise<PlanogramExtended> =>
    api.put<PlanogramExtended>(`${this.link}/${id}`, trimItems(data, ['name'])).then(({ data }) => data);

  deletePlanogram = (id: number) => api.delete(`${this.link}/${id}`);

  getPlanogramInfos = (planogramId: number, versionId: number): Promise<PlanogramInfo[]> =>
    api
      .get<PlanogramInfo[]>(`${this.link}/${planogramId}${this.versions}/${versionId}${this.subLink}`)
      .then(({ data }) => data);

  editPlanogramInfo = (id: number, versionId: number, infoId: number, data: PlanogramInfo) =>
    api.put(`${this.link}/${id}${this.versions}/${versionId}${this.subLink}/${infoId}`, data);

  addPlanogramInfos = (id: number, data: PlanogramInfo) => api.post(`${this.link}/${id}${this.subLink}`, data);

  editPlanogramInfos = (id: number, data: PlanogramInfo, planogramInfoId: number) =>
    api.put(`${this.link}/${id}${this.subLink}/${planogramInfoId}`, data);

  getPlanogramShareButtons = (planogramId: number, versionId: number): Promise<SharingButton> =>
    api
      .get<SharingButton>(`${this.link}/${planogramId}${this.versions}/${versionId}${this.shareButtons}`)
      .then(({ data }) => data);

  editPlanogramShareButtons = (
    planogramId: number,
    versionId: number,
    shareBtnId: number,
    data: SharingButton,
  ): Promise<PlanogramSharingButtonResponse> =>
    api
      .put(
        // eslint-disable-next-line max-len
        `${this.link}/${planogramId}${this.versions}/${versionId}${this.shareButtons}/${shareBtnId}`,
        data,
      )
      .then(({ data }) => data);

  deletePlanogramInfos = (id: number, planogramInfoId: number) =>
    api.delete(`${this.link}/${id}${this.subLink}/${planogramInfoId}`);

  cloneToDraftVersion = (planogramId: number, versionId: number) =>
    api.post(`${this.link}/${planogramId}${this.versions}/${versionId}/clone_to_draft`);

  publishVersion = (planogramId: number, versionId: number) =>
    api.post(`${this.link}/${planogramId}${this.versions}/${versionId}/publish`);

  generateVersion = (planogramId: number, versionId: number) =>
    api.post(`${this.link}/${planogramId}${this.versions}/${versionId}/generate`);

  stopGeneratingVersion = (planogramId: number, versionId: number) =>
    api.post(`${this.link}/${planogramId}${this.versions}/${versionId}/stop_generation`);

  editVersion = (
    planogramId: number,
    versionId: number,
    data: Partial<PlanogramVersionUpdateParams>,
  ): Promise<PlanogramVersionUpdateResponse> =>
    api
      .put<PlanogramVersionUpdateResponse>(`${this.link}/${planogramId}${this.versions}/${versionId}`, data)
      .then(({ data }) => data);

  regeneratePublishedCredentials = (planogramId: number, versionId: number): Promise<PlanogramVersion> =>
    api
      .put<Record<'planogramVersion', PlanogramVersion>>(
        `${this.link}/${planogramId}${this.versions}/${versionId}/regenerate_credentials`,
      )
      .then(({ data }) => data.planogramVersion);

  detachPNG = (planogramId: number, versionId: number) =>
    api.put(`${this.link}/${planogramId}${this.versions}/${versionId}/image_purge`);

  getPlanogramSearchSettings = (planogramId: number, versionId: number): Promise<SearchSettings> =>
    api
      .get<SearchSettings>(`${this.link}/${planogramId}${this.versions}/${versionId}${this.searchSettings}`)
      .then(({ data }) => data);

  putPlanogramSearchSettings = (
    planogramId: number,
    versionId: number,
    id: number,
    data: Partial<SearchSettings>,
  ): Promise<SearchSettings> =>
    api
      .put<SearchSettings>(`${this.link}/${planogramId}${this.versions}/${versionId}${this.searchSettings}/${id}`, data)
      .then(({ data }) => data);

  getDownloadPNGLink = (planogramId: number, versionId: number): Promise<string> =>
    api
      .get<Record<'pngUrl', string>>(`${this.link}/${planogramId}${this.versions}/${versionId}${this.downloadPng}`)
      .then(({ data }) => data)
      .then(({ pngUrl }) => pngUrl);

  getDownloadSVGFile = (planogramId: number, versionId: number) =>
    api
      .get(`${this.link}/${planogramId}${this.versions}/${versionId}${this.downloadSvg}`, {
        responseType: 'blob',
      })
      .then(({ data }) => data);

  getOverlaySettings = (planogramId: number, versionId: number): Promise<ECommerceOverlaySettings[]> =>
    api
      .get<ECommerceOverlaySettings[]>(
        `${this.link}/${planogramId}${this.versions}/${versionId}${this.ecommerceSettings}`,
      )
      .then(({ data }) => data);

  createOverlaySettings = (
    planogramId: number,
    versionId: number,
    overlayType: string,
  ): Promise<Record<'ecommerceOverlaySettings', ECommerceOverlaySettings>> =>
    api
      .post<Record<'ecommerceOverlaySettings', ECommerceOverlaySettings>>(
        `${this.link}/${planogramId}${this.versions}/${versionId}${this.ecommerceSettings}`,
        { overlayType },
      )
      .then(({ data }) => data);

  deleteOverlaySettings = (planogramId: number, versionId: number, settingsId: number) =>
    api
      // eslint-disable-next-line max-len
      .delete(`${this.link}/${planogramId}${this.versions}/${versionId}${this.ecommerceSettings}/${settingsId}`);

  updateEcommerceSettings = (
    ecommerceOverlaySetting: Partial<ECommerceOverlaySettings>,
    planogramId: number,
    versionId: number,
  ) =>
    api
      .put<Record<'ecommerceOverlaySettings', ECommerceOverlaySettings>>(
        // eslint-disable-next-line max-len
        `${this.link}/${planogramId}${this.versions}/${versionId}${this.ecommerceSettings}/${ecommerceOverlaySetting.id}`,
        { ecommerceOverlaySetting },
      )
      .then(({ data }) => data);

  // eslint-disable-next-line class-methods-use-this
  clonePlanogram = (body: PlanogramCloneParams) => api.post('/clone', body).then(({ data }) => data);

  getPlanogramObjects = (planogramId: number): Promise<PlanogramObjectsResponse> =>
    api.get<PlanogramObjectsResponse>(`${this.link}/${planogramId}/planogram_objects`).then(({ data }) => data);

  getAnimationPath = (planogramId: number): Promise<AnimationPathResponse> =>
    api.get<AnimationPathResponse>(
      `${this.link}/${planogramId}${this.animationPath}`
      )
      .then(({ data }) => data);
}

export const planogramsModel = new PlanogramsModel();
