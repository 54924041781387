import React from 'react';

import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';

export function SuccessIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 17 17">
      <path fillRule="evenodd" clipRule="evenodd" d="M8 3.045a5.455 5.455 0 1 0 0 10.91 5.455 5.455 0 0 0 0-10.91zM1.335 8.5a6.667 6.667 0 1 1 13.333 0 6.667 6.667 0 0 1-13.333 0z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.524 6.016a.611.611 0 0 1 .872 0 .697.697 0 0 1 0 1.003l-4.074 3.964a.593.593 0 0 1-.436.183.593.593 0 0 1-.436-.183L4.598 9.181a.698.698 0 0 1 0-1.003.611.611 0 0 1 .872 0l1.416 1.378 3.638-3.54z" fill="currentColor"/>
    </SvgIcon>
  );
}
