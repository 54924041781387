import { Box as MuiBox, IconButton as MuiIconButton, styled } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const VideoContainer = styled(MuiBox)({
  position: 'relative',
  background: COLORS.cozmosPurple4,
});

export const DialogContainer = styled(MuiBox)({
  overflowY: 'auto',
  borderRadius: '8px',
});

export const VideoBackdrop = styled(MuiBox)({
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '100%',
  height: '100%',
  backdropFilter: 'blur(1px)',
  backgroundColor: 'rgba(40, 34, 50, 0.2)',
  '.playing &': {
    backdropFilter: 'none',
    backgroundColor: 'transparent',
  },
});

export const IconWrapper = styled(MuiBox)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '64px',
  height: '64px',
  borderRadius: '50%',
  border: `2px solid ${COLORS.white}`,
  transform: 'translate(-50%, -50%)',
  cursor: 'pointer',
  '& svg': {
    width: '50%',
    height: '50%',
    color: COLORS.white,
  },
});

export const IconButton = styled(MuiIconButton)({
  position: 'absolute',
  top: '-20px',
  right: '-48px',
  width: '40px',
  height: '40px',
  color: COLORS.white,
  borderRadius: '50%',
  backgroundColor: 'rgba(29, 30, 39, 0.80)',
  backdropFilter: 'blur(4px)',
  '&:hover': {
    backgroundColor: 'rgba(29, 30, 39, 1)',
  },
});
