import React, { memo } from 'react';

import { NavigationArrowType } from 'types/enums';
import { NavigationArrow, NavigationArrowAsset } from 'types/other';
import { getHexadecimal } from 'utils';
import { ButtonPreviewWrapper } from 'components/ButtonPreviewWrapper/ButtonPreviewWrapper';

import { Box } from './style';

export interface NavigationArrowsPreviewProps {
  settings: NavigationArrow;
}

export const NavigationArrowsPreviewComponent = ({
  settings
}: NavigationArrowsPreviewProps): JSX.Element => {
  const transparency = getHexadecimal(24);
  const leftArrow = settings.navigationArrowOtherAssets.find(image =>
    image.direction === NavigationArrowType.LEFT);
  const rightArrow = settings.navigationArrowOtherAssets.find(image =>
    image.direction === NavigationArrowType.RIGHT);

  const renderIcon = (item: NavigationArrowAsset | undefined) => {
    const blurColor = `${settings.backgroundColor}${transparency}`;

    return (
      <Box
        my={4}
        mx={3}
        className="arrow-button"
        style={{
          boxShadow: `${settings.disableGlowEffect ? '' : `0 1px 9px 1px ${blurColor}`}`
        }}
      >
        <Box className="arrow-button-icon-container">
          {item?.otherAsset.url && <img src={item.otherAsset.url} alt=""/>}
        </Box>
      </Box>
    );
  };

  return (
    <ButtonPreviewWrapper>
      <Box id="arrows-wrapper">
        {renderIcon(leftArrow)}
        {renderIcon(rightArrow)}
      </Box>
    </ButtonPreviewWrapper>
  )
}

export const NavigationArrowsPreview = memo(NavigationArrowsPreviewComponent);
