import React, { useEffect, useState } from 'react';

import { Box, Grid, Typography, styled } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { CardComponent, SettingsProviderButton, PageHeader } from 'components';
import { useServerErrorActions } from 'contexts/ServerErrorContext';
import { useBusyProgressActions } from 'contexts/BusyProgressContext';
import { socialIntegrationModel } from 'models';
import { SocialContent } from 'types';
import { ROUTES } from 'routes/constants';
import { COLORS } from 'theme/constants';
import config from 'config/Config';

import { SOCIAL_PROVIDERS_ICON_MAP } from './constants';

const SocialIntegration = () => {
  const { t } = useTranslation();
  const [socialProviders, setSocialProviders] = useState<Array<SocialContent>>([]);
  const { handleServerError } = useServerErrorActions();
  const { withPageProgressHandler } = useBusyProgressActions();

  useEffect(() => {
    getSocialIntegrationOptions();
  }, []);

  const getSocialIntegrationOptions = withPageProgressHandler(() =>
    socialIntegrationModel.getSocialProviders().then(setSocialProviders).catch(handleServerError),
  );

  return (
    <>
      <PageHeader title={t('pages.settings.socialIntegration.title')} backLink={ROUTES.settings} />
      <Grid container spacing={6}>
        <CardComponent sm={12}>
          <Box display="flex">
            {socialProviders.map(
              ({ title, id }) =>
                !(title === 'Instagram' && config.app_env === 'prod') && (
                  <SettingsProviderButton
                    key={title}
                    Icon={SOCIAL_PROVIDERS_ICON_MAP[title as keyof typeof SOCIAL_PROVIDERS_ICON_MAP] ?? 'svg'}
                    title={title}
                    to={`${ROUTES.socialIntegrationNamespace}${id}`}
                    style={{ marginRight: '24px' }}
                  />
                ),
            )}
          </Box>
        </CardComponent>
      </Grid>
    </>
  );
};

export default SocialIntegration;
