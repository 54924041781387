import {
  Typography as MuiTypography,
  Grid as MuiGrid,
  Box as MuiBox,
  Checkbox as MuiCheckbox,
  withStyles
} from '@material-ui/core';

export const Typography = withStyles(theme => ({
  root: {
    padding: '8px 16px 0 0',
    '&.name': { marginRight: 'auto', fontWeight: '700' },
    '&.accessibility-hint-text': { fontWeight: '600' },
    '&.text-wrapper': { alignSelf: 'center', marginRight: 'auto' },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&:hover': { textDecoration: 'underline' },
    },
    '&.pt-0': { paddingTop: '0px' },
    whiteSpace: 'pre-line',
  },
}))(MuiTypography);

export const Grid = withStyles({
  root: {
    marginTop: '10px',
    '& .right': { marginLeft: 'auto' },
  },
})(MuiGrid);

export const Checkbox = withStyles(theme => ({
  root: {
    '&.MuiCheckbox-indeterminate': {
      color: theme.palette.primary.main,
    }
  }
}))(MuiCheckbox);

export const Box = withStyles(theme => ({
  root: {
    background: 'white',
    textAlign: 'left',
  },
}))(MuiBox);
