import React from 'react';

import { Box, Button, Grid } from '@material-ui/core';
import Helmet from 'react-helmet';

import { ROUTES } from 'routes/constants';
import { HeadingL } from 'theme/globalStyles';

import { RouterLink } from 'components/style';

const CloneRequestExpiredPage = () => (
  <Box padding="100px 0">
    <Helmet title="Copy request expired" />
    <Grid container direction="column">
      <Grid item style={{ marginBottom: '40px' }}>
        <HeadingL align="center" style={{ fontWeight: 600 }}>
          Copy request expired or already handled
        </HeadingL>
      </Grid>
      <Grid item container alignItems="center" justify="center">
        <Button component={RouterLink} to={ROUTES.home} variant="contained" color="primary" size="small">
          Return to the website
        </Button>
      </Grid>
    </Grid>
  </Box>
);

export default CloneRequestExpiredPage;
