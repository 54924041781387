import { PaginationParams } from './common';

export enum ImageStatus {
  PENDING = 'pending',
  DOWNLOADING = 'downloading',
  CREATING_VARIANTS = 'creating_variants',
  VALIDATION_DIMENSIONS = 'validating_dimensions',
  DIMENSIONS_OK = 'dimensions_ok',
  DIMENSIONS_ERROR = 'dimensions_error',
  PREPARING_TO_CUT_TILES = 'preparing_to_cut_tiles',
  CUTTING_TILES = 'cutting_tiles',
  UPLOADING_TILES = 'uploading_tiles',
  ERROR_GENERATING = 'error_generating',
  GENERATED = 'generated',
}

export interface UploadedPicture extends Record<string, unknown> {
  id: number;
  accessibilityDescription: string | null;
  imageName: string;
  path: string;
  seoTitle: string | null;
  url: string;
  usedOnPlanogram: boolean;
  tags?: Tag[];
  thumbnails?: Record<string, string>;
  contentType: string;
}

export interface Image extends UploadedPicture {
  curatorReady: boolean;
  fromShopify: boolean;
  hasVariant: boolean;
  originalHeight: number;
  originalWidth: number;
  status: ImageStatus | null;
  shouldUsedOnPlanogram?: boolean;
  isSystem?: boolean;
  isGallery: boolean;
}

export interface FileWithUploadData extends File {
  upload: {
    uuid: string;
    filename: string;
  };
}

export interface FileWithSignedId extends FileWithUploadData {
  signed_id: string;
}

export interface PdfPicture {
  signedId: string;
}

export interface ImageWithInlineStyles extends Image {
  inlineStyles?: {
    backgroundColor: string;
  };
}

export interface Tag {
  // undefined variant is needed for 'add new tag' case to emulate
  // an actual tag data for BE where id is required
  id: number | undefined;
  name: string;
  taggingRecordsCount?: number;
  pictureIds?: number[];
  adminName?: string;
  updatedAt?: string;
  color?: string;
  taggingRecords?: Image[];
}

export interface FileValidationError {
  code: string;
  message: string;
}

export type FileLoadFunc<T, R> = (file: FileWithSignedId, additionalParam?: T) => Promise<R>;

export interface BGImagesResponse {
  backgroundImages: Image[];
  count: number;
}

export interface TagsListResponse {
  tags: Tag[];
  tagsCount: number;
}

export interface TagsSearchParams extends PaginationParams {
  orderDir?: string;
  orderBy?: string;
  name?: string;
}

export interface PicturesTagParams {
  id: number | undefined;
  tag: {
    pictureIds?: number[];
    videoIds?: number[];
  };
}

export interface PicturesGetParams extends PaginationParams {
  tagId?: number;
  instagram?: boolean;
  search?: string;
}

export interface PicturesListResponse {
  pictures: Image[];
  picturesCount: number;
}

export interface AddPictureParams {
  signedId: string;
  productId?: number | string;
}

export interface AddPdfPictureParams {
  documentSignedId: string;
  productId?: number | string;
}

export interface EditPictureParams {
  accessibilityDescription: string | null;
  seoTitle: string | null;
}

export interface UseOnPlanogramImagesParams {
  id: number;
  usedOnPlanogram: boolean;
}

export interface OtherAssetsResponse {
  otherAssets: UploadedPicture[];
  otherAssetsCount: number;
}

export interface LogosListResponse {
  logos: UploadedPicture[];
  logosCount: number;
}

export interface FaviconsListResponse {
  favicons: UploadedPicture[];
  count: number;
}

export interface PdfStatus {
  id: number;
  status: string;
  pictures?: Image[];
}
