import { DropEvent } from 'react-dropzone';
import i18n from 'i18next';

import { FileValidator, HandleServerErrorFunc } from 'types';
import { getServerError } from 'utils/serverErrors';

export const onFilesDrop = async (
  event: DropEvent,
  handleServerError: HandleServerErrorFunc,
  extensionsValidator: FileValidator,
  accept: string,
): Promise<Array<File>> => {
  const acceptedFiles: File[] = [];
  let fileList: FileList | undefined;

  if ('dataTransfer' in event) {
    fileList = event.dataTransfer?.files;
  }

  if ('files' in event.target!) {
    fileList = event.target?.files ? event.target.files : undefined;
  }

  if (fileList?.length) {
    await Promise.all(
      Array.from(fileList).map(async file => {
        try {
          if (!(await extensionsValidator.rule(file, accept))) {
            handleServerError(getServerError(extensionsValidator.message));
            return;
          }
        } catch (e) {
          handleServerError(getServerError(i18n.t('errorsTexts.imageUploadError')));
          return;
        }

        acceptedFiles.push(file);
      }),
    );
  }

  return acceptedFiles;
};
