import React from 'react';

import { Box, Checkbox, Grid, Typography } from '@material-ui/core';

import { ImageChooseComponent } from 'components';
import { IMAGE_UPLOAD_CAPTION } from 'components/constants';
import { controlButtonModel } from 'models';
import { PlanogramImage, PlanogramVersionControlButton } from 'types/planograms';
import { useServerErrorActions } from 'contexts/ServerErrorContext';

import { ImageUploadCaption } from 'components/style';

interface ControlButtonSettingsProps {
  title?: string;
  settings: PlanogramVersionControlButton;
  setSettings: (settings: Partial<PlanogramVersionControlButton>) => void;
}

export function ControlButtonSettings({ title, settings, setSettings }: ControlButtonSettingsProps) {
  const { handleServerError } = useServerErrorActions();

  const setImage = (controlButton: PlanogramImage) => setSettings({ controlButton });
  const getImages = (params: any) => controlButtonModel.getImages(params).catch(handleServerError);

  return (
    <>
      <Grid container spacing={6}>
        {title && (
          <Grid item sm={12} style={{ padding: '0 12px' }}>
            <Typography variant="h4" display="inline">
              {title}
            </Typography>
          </Grid>
        )}
        <Grid item sm={4} style={{ minWidth: '150px' }}>
          <ImageChooseComponent
            image={settings.controlButton}
            setImage={setImage}
            handleServerError={handleServerError}
            dialogTitle="Choose control button"
            getPictures={getImages}
            withDelete
          />
          <Box ml={-2}>
            <Typography variant="caption">
              <Checkbox
                color="primary"
                checked={settings.showShadow ?? false}
                onChange={e => setSettings({ showShadow: e.target.checked })}
              />
              <span>Show shadow</span>
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={8}>
          <ImageUploadCaption>{IMAGE_UPLOAD_CAPTION.controlButton}</ImageUploadCaption>
        </Grid>
      </Grid>
    </>
  );
}
