import { Tabs as MuiTabs, Tab as MuiTab, Box as MuiBox, withStyles } from '@material-ui/core';

import { COLORS } from 'theme/constants';

export const Tabs = withStyles({
  root: {
    '& .MuiTabs-indicator': { height: 0 },
    '&.my8': { margin: '8px 0' },
    minHeight: 'auto',
    '&.pictureTabs': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '-8px',
    },
    '&.animationTabs': {
      '& .MuiTab-root:first-child': {
        borderRadius: '4px 0 0 4px',
      },
      '& .MuiTab-root:last-child': {
        borderRadius: '0 4px 4px 0',
      },
      '& .MuiTab-root': {
        minWidth: '100px',
        minHeight: '30px',
      },
    },
  },
})(MuiTabs);

export const Tab = withStyles(theme => ({
  root: {
    backgroundColor: COLORS.greyBorder,
    color: COLORS.greyTabText,
    opacity: 1,
    minHeight: 'auto',
    minWidth: 'auto',
    padding: '4px 16px',
    borderRight: '1px solid rgba(29, 30, 39, 0.12)',
    fontSize: '12px',
    '&.error': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.error.main,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '&:first-child, &.frequencyTypeTab:first-child': { borderRadius: '6px 0 0 6px' },
    '&:last-child, &.frequencyTypeTab:last-child': { borderRadius: '0 6px 6px 0', borderRight: 'none' },
    '&.lang': {
      ...theme.typography.caption,
      display: 'inline-block',
      borderRadius: '4px',
      minWidth: '26px',
      textAlign: 'center',
      height: '24px',
      marginRight: '4px',
      padding: '4px 8px',
    },
    '&.pictureTab, &.refreshTab': {
      display: 'inline-block',
      minWidth: '40px',
      textAlign: 'center',
      marginRight: '10px',
      height: '28px',
      borderRadius: '6px',
      '&..Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    '&.refreshTab, &.frequencyTypeTab': {
      height: '30px',
      padding: '4px 10px',
      borderRadius: '4px',
    },
    '&.frequencyTypeTab': {
      minWidth: '80px',
      padding: '4px 9px',
    },
  },
}))(MuiTab);

export const Box = withStyles({
  root: {
    width: '100%',
    '&.shopify-description': {
      order: 2,
    },
  },
})(MuiBox);
