import { ReactComponent as InstagramIcon } from 'assets/img/instagram.svg';
import { ReactComponent as FacebookIcon } from 'assets/img/facebook.svg';
import { ReactComponent as LinkfireIcon } from 'assets/img/linkfire.svg';
import { ReactComponent as SnapchatIcon } from 'assets/img/snapchat.svg';
import { ReactComponent as TiktokIcon } from 'assets/img/tiktok.svg';
import { ReactComponent as TwitchIcon } from 'assets/img/twitch.svg';
import { ReactComponent as TwitterIcon } from 'assets/img/twitter.svg';
import { ReactComponent as YoutubeIcon } from 'assets/img/youtube.svg';

export const SOCIAL_PROVIDERS_ICON_MAP = {
  Instagram: InstagramIcon,
  Facebook: FacebookIcon,
  Linkfire: LinkfireIcon,
  Snapchat: SnapchatIcon,
  Tiktok: TiktokIcon,
  Twitch: TwitchIcon,
  X: TwitterIcon,
  Youtube: YoutubeIcon
}

export enum SocialContentTypeKeys {
  NAME = 'contentTitle',
  TYPE = 'contentType',
  ID = 'contentValue',
}
